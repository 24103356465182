@if (taskSnoozeComponentService.snoozeDateControlValueChanges$ | async) {}

<div
  class="pointer"
  [ngClass]="{
    'icon-layout': appearance === 'icon',
    'detail-layout': appearance === 'detail',
    'appear-hover':
      appearance === 'icon' && !taskSnoozeComponentService.showUnSnoozed$() && !taskSnoozeComponentService.isSnoozed$(),
  }"
  [formControl]="taskSnoozeComponentService.snoozeDateControl"
  [dougsDatepicker]="datepickerSnooze"
  [customDatePills]="taskSnoozeComponentService.datePickerPills"
  (click)="$event.stopPropagation()"
  ngDefaultControl
>
  @if (taskSnoozeComponentService.showUnSnoozed$()) {
    <div class="active-unsnoozed">
      <i
        class="fal mr-4"
        [ngClass]="taskSnoozeComponentService.loading$() ? 'fa-spinner fa-spin' : 'fa-alarm-snooze'"
      ></i>
      @if (appearance === 'detail') {
        <span>Snooze terminé</span>
      }
    </div>
  } @else {
    <div
      [class.active-snooze]="
        (appearance === 'detail' && !taskSnoozeComponentService.isSnoozed$()) ||
        (appearance === 'icon' && taskSnoozeComponentService.isSnoozed$())
      "
    >
      <i class="fal mr-4" [ngClass]="taskSnoozeComponentService.loading$() ? 'fa-spinner fa-spin' : 'fa-snooze'"></i>
      @if (appearance === 'detail') {
        @if (taskSnoozeComponentService.isSnoozed$()) {
          <span>Jusqu'au {{ taskSnoozeComponentService.task$()?.startDate | date: 'dd/MM/yyyy' }}</span>
        } @else {
          <span>Snoozer la tache</span>
        }
      }
    </div>
  }
</div>
<dougs-datepicker #datepickerSnooze [options]="{ minDate: taskSnoozeComponentService.task$()?.createdAt }" />
