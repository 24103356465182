<ng-container *ngIf="cockpitStatsCollaboratorComponentService.refreshCollaboratorStats$ | async" />
<ng-container *ngIf="cockpitStatsTeamComponentService.refreshTeamStats$ | async" />
<ng-container *ngIf="cockpitStatsTeamComponentService.refreshAccountingTeamStats$ | async" />
<ng-container *ngIf="cockpitStatsDepartmentComponentService.refreshDepartmentStats$ | async" />

<div
  *ngIf="(cockpitStatsComponentService.shouldShowStats$ | async) && showCockpitStats"
  [@slideInOut]
  class="border-bottom"
>
  <div class="p-16">
    <dougs-cockpit-stats
      *ngIf="(cockpitStateService.domain$ | async) === 'collaborator'"
      [collaboratorStats]="cockpitStatsCollaboratorComponentService.collaboratorStats$ | async"
      [isLoading]="cockpitStatsTeamComponentService.isLoadingCollaboratorStats$ | async"
    />

    <dougs-cockpit-stats
      *ngIf="
        (cockpitStateService.domain$ | async) === 'team' &&
        !(cockpitStatsComponentService.showAccountingDashboard$ | async)
      "
      [collaboratorStats]="cockpitStatsTeamComponentService.teamStats$ | async"
      [isLoading]="cockpitStatsTeamComponentService.isLoadingTeamStats$ | async"
    />

    <dougs-cockpit-accounting-stats
      *ngIf="
        (cockpitStateService.domain$ | async) === 'team' &&
        (cockpitStatsComponentService.showAccountingDashboard$ | async)
      "
      [stats]="cockpitStateService.accountingTeamStats$ | async"
      [isLoading]="cockpitStatsTeamComponentService.isLoadingTeamStats$ | async"
    />

    <dougs-cockpit-stats
      *ngIf="(cockpitStateService.domain$ | async) === 'department'"
      [departmentStats]="cockpitStatsDepartmentComponentService.departmentStats$ | async"
      [isLoading]="cockpitStatsDepartmentComponentService.isLoadingDepartmentStats$ | async"
    />
  </div>
</div>

<div class="px-32">
  <div class="tabs-container">
    <dougs-cockpit-tabs />
    <div
      *ngIf="cockpitStatsComponentService.shouldShowStats$ | async"
      (click)="showCockpitStats = !showCockpitStats"
      class="display-container pointer"
    >
      <p class="tiny">{{ showCockpitStats ? 'Masquer' : 'Afficher' }} le tableau de bord</p>
      <i class="fal" [ngClass]="{ 'fa-chevron-up': showCockpitStats, 'fa-chevron-down': !showCockpitStats }"></i>
    </div>
  </div>
</div>
