import { Inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import { ProductsService } from '@dougs/revenue/products/shared';
import { SocialSubscriptionPlans, SubscriptionPlan } from '@dougs/subscription/dto';
import { SocialSubscriptionStateService, SubscriptionStateService } from '@dougs/subscription/shared';

@Injectable()
export class SuggestProductModalService {
  private readonly isLoading: WritableSignal<boolean> = signal(false);
  isLoading$: Signal<boolean> = this.isLoading.asReadonly();

  formGroup: FormGroup = new FormGroup<{
    desiredProduct: FormControl<string>;
    comment: FormControl<string>;
  }>({
    desiredProduct: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
    comment: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
  });

  constructor(
    private readonly subscriptionStateService: SubscriptionStateService,
    private readonly socialSubscriptionStateService: SocialSubscriptionStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly productsService: ProductsService,
    public readonly modalRef: ModalRef,
    @Inject(MODAL_DATA)
    public data: { isSocial: boolean },
  ) {}

  refreshPlans$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    tap(() => this.isLoading.set(true)),
    concatMap((activeCompany) =>
      this.data.isSocial
        ? this.socialSubscriptionStateService.refreshSocialPlans(activeCompany.id)
        : this.subscriptionStateService.refreshPlans(activeCompany.id),
    ),
    tap(() => this.isLoading.set(false)),
  );

  plans$: Observable<SubscriptionPlan[]> = combineLatest([
    this.companyStateService.activeCompanyIdChanged$,
    this.data.isSocial ? this.socialSubscriptionStateService.socialPlans$ : this.subscriptionStateService.plans$,
  ]).pipe(
    map(([activeCompany, plans]) =>
      plans.filter((plan) =>
        this.data.isSocial
          ? plan.id !== SocialSubscriptionPlans.Assimile && plan.id !== activeCompany.socialSubscription?.plan
          : plan.id !== activeCompany.subscription?.plan,
      ),
    ),
  );

  async sendProductSuggestion(): Promise<void> {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.valid) {
      this.isLoading.set(true);

      await this.productsService.suggestProductForCompany(
        this.companyStateService.activeCompany.id,
        this.formGroup.controls.desiredProduct.value,
        this.formGroup.controls.comment.value,
        this.data.isSocial,
      );

      this.modalRef.close();
      this.isLoading.set(false);
    }
  }
}
