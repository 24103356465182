<div dougsModalTitle>
  <h6>
    <i class="fal fa-receipt mr-4"></i>
    Configurer la tâche
    <i (click)="showRemarks()" class="fal fa-question-circle pl-4 pointer" *ngIf="data.task.hasRemarks"></i>
  </h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent="xlarge">
  <div class="double-fragment-task-form">
    <dougs-task-form-fields
      (submitTaskForm)="updateTaskForm()"
      (cancelTaskForm)="cancelTaskForm()"
      [task]="data.task"
      [taskFormInlineGroup]="data.form?.modalGroup"
    />
    <dougs-files-preview *ngIf="data.task.attachments.length" [attachments]="data.task.attachments" />
  </div>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Fermer</dougs-button>
</div>
