<div
  class="legend mt-16"
  [ngClass]="{
    'one-column': legendComponentService.oneColumnLegend$ | async,
  }"
>
  <div></div>
  <div class="legend__activity" *ngIf="cockpitAccountingStatsComponentService.showActivityBar$ | async">
    <ng-container *ngIf="!(cockpitAccountingStatsComponentService.isCompletedStats$ | async)">
      <div class="legend">
        <dougs-badge size="small" type="admin" />
        <p class="xtiny">Assignée cette semaine</p>
      </div>
      <div class="legend">
        <dougs-badge size="small" type="pink" />
        <p class="xtiny">Assignée avant</p>
      </div>
    </ng-container>
    <div class="legend">
      <dougs-badge size="small" type="green-300" />
      <p class="xtiny">Complétée</p>
    </div>
    <div class="legend">
      <div class="legend__bar"></div>
      <p class="xtiny">Productivité moy. (4 dern. semaines)</p>
    </div>
  </div>
  <div
    *ngIf="legendComponentService.showBilanLegend$ | async"
    class="legend__bilan"
    [ngClass]="{ 'self-end': !(cockpitAccountingStatsComponentService.activityBilanToggleMode$ | async) }"
  >
    <div class="legend">
      <dougs-badge size="small" type="success" />
      <p class="xtiny">QB complété</p>
    </div>
    <div class="legend">
      <dougs-badge size="small" type="primary" />
      <p class="xtiny">Données préparées</p>
    </div>
    <div class="legend">
      <dougs-badge size="small" type="turquoise" />
      <p class="xtiny">QB vérifié</p>
    </div>
    <div class="legend">
      <dougs-badge size="small" type="primary-700" />
      <p class="xtiny">Révision effectuée</p>
    </div>
  </div>
</div>
