<div class="bank-statement-card" [ngClass]="{ 'bank-statement-card--check': validated }">
  <div
    *ngIf="title"
    class="bank-statement-card__title mb-16"
    [ngClass]="{ 'bank-statement-card__title--check': validated, 'color-success': validated }"
  >
    <div
      class="bank-statement-card__title__pill mr-8"
      [ngClass]="{ 'bank-statement-card__title__pill--check': validated }"
    >
      <i class="fal fa-check" *ngIf="validated"></i>
    </div>
    <h5>{{ title }}</h5>
  </div>

  <div class="bank-statement-card__content">
    <ng-content />
  </div>
</div>
