<div dougsModalTitle>
  <h6>{{ dsnAvailableModalComponentService.modalTitle }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <dougs-avatar-message
    [avatarUrl]="dsnAvailableModalComponentService.avatarUrl"
    [fullName]="dsnAvailableModalComponentService.collaboratorName"
    size="medium"
  >
    <p class="small mb-8">
      Nous vous rappelons que les montants indiqués avec la mention “règlements effectués par nos soins” sont réglés par
      le biais du télérèglement de la DSN.
    </p>

    <p class="small bold mb-8">
      Attention : les montants indiqués avec la mention “règlements à effectuer par vos soins” sont des règlements que
      vous devez effectuer par le biais d’un virement bancaire ou sur votre espace client avant le 15 du mois.
    </p>

    <p class="small mb-8">
      Si vous avez mis en place un mandat SEPA pour les organismes indiqués avec la mention “règlements à faire par vos
      soins”, il faut nous en informer par le biais du tchat afin que nous paramétrions la DSN dans ce sens. Ainsi, le
      paiement se fera directement en télérèglement sur les prochaines DSN et vous n’aurez plus à le faire manuellement.
    </p>

    <p class="small bold mb-8">
      Dougs ne pourra pas être tenu responsable des retards ou absences de règlements pour lesquels les télérèglements
      n'ont pas été mis en place et qui doivent être faits par le client.
    </p>

    <p class="small mb-8">
      Les retards ou absences de versements peuvent entraîner des pénalités de retard ou des taxations d’office de la
      part de certains organismes.
    </p>

    <p class="small">
      Afin de simplifier votre quotidien d’entrepreneur, nous vous conseillons donc de mettre en place le télérèglement
      pour tous les organismes et de nous en tenir informés afin que nous puissions le paramétrer pour vos DSN.
    </p>
  </dougs-avatar-message>
  <dougs-file-details
    class="mt-16"
    *ngFor="let attachment of dsnAvailableModalComponentService.attachments$ | async; trackBy: 'id' | trackBy"
    [attachment]="attachment"
  />
</div>
<div dougsModalFooter>
  <dougs-button type="button" [dougsModalClose]="true">D'accord !</dougs-button>
</div>
