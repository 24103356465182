import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { map, Observable } from 'rxjs';
import { BillingInvoice } from '@dougs/subscription/dto';
import { BillingInvoiceStateService } from '@dougs/subscription/shared';

@Pipe({
  name: 'invoiceHasCompliantMethodState',
  standalone: true,
})
export class InvoiceHasCompliantMethodStatePipe implements PipeTransform {
  constructor(private readonly billingInvoiceStateService: BillingInvoiceStateService) {}

  transform(billingInvoice: BillingInvoice, billableItems: FormArray): Observable<boolean> {
    return this.billingInvoiceStateService.billingInvoices$.pipe(
      map((billingInvoices) => {
        const firstBillableItemWithInvoiceNumber: AbstractControl | undefined = billableItems.controls.find(
          (formControl) => !!formControl.get('metadata')?.get('creditedBillingInvoiceNumber'),
        );

        if (!firstBillableItemWithInvoiceNumber?.get('metadata')?.get('creditedBillingInvoiceNumber')?.value) {
          return true;
        }

        const billingInvoiceFound: BillingInvoice | undefined = billingInvoices.find(
          (billingInvoiceIterated) =>
            billingInvoiceIterated.number ===
            firstBillableItemWithInvoiceNumber.get('metadata')?.get('creditedBillingInvoiceNumber')?.value,
        );

        if (!billingInvoiceFound) {
          return true;
        }

        return billingInvoice.paymentMethod === billingInvoiceFound.paymentMethod;
      }),
    );
  }
}
