<div class="cockpit-accounting-header">
  <div class="cockpit-accounting-header__total px-8">
    <div class="img-label" *ngIf="cockpitStateService.team$ | async as team">
      <img [src]="team.avatarUrl | defaultAvatarUrl" [alt]="team.id" />
      <p class="name tiny color-primary-700">{{ team.name | titlecase }}</p>
    </div>
    <ng-container *ngIf="!(cockpitStatsTeamComponentService.isLoadingTeamStats$ | async); else skeletonLoader">
      <div>
        <p class="tiny">Total</p>
        <p class="small color-primary-700">{{ stats?.activity?.points || 0 }} pts</p>
      </div>
      <div>
        <p class="tiny">Prio</p>
        <p class="small color-primary-700">{{ stats?.activity?.priorityTasks || 0 }}</p>
      </div>
      <div>
        <p class="tiny">Bilans</p>
        <p class="small color-primary-700">{{ stats?.activity?.balanceSheets || 0 }}</p>
      </div>
    </ng-container>
    <ng-template #skeletonLoader>
      <div class="skeleton-mono-direction skeleton-mono-direction__header"></div>
    </ng-template>
  </div>
  <div class="accounting-stats__header__period">
    <dougs-cockpit-stats-period [disableFuture]="true" />
  </div>
</div>
