<div class="documents-available">
  <h5>Les documents ont été mis à disposition du client avec succès.</h5>
  <div class="documents-available__stepper mt-16">
    <dougs-stepper>
      <dougs-step [status]="StepStatus.SUCCESS">
        <div dougsStepTitle>
          <div class="documents-step-title">
            <p class="small color-success bold">Mail automatique envoyé au client</p>
          </div>
        </div>
        <div dougsStepSubtitle>
          <p class="tiny color-gray">
            Un mail a été envoyé au client pour le prévenir. Il contient un
            <a class="color-gray" rel="noopener" [href]="task.targetId + '/services'" target="_blank"
              >lien vers ses prestations</a
            >.
          </p>
        </div>
      </dougs-step>
      <dougs-step [status]="StepStatus.SUCCESS" [isLast]="true">
        <div dougsStepTitle>
          <div class="documents-step-title">
            <p class="small color-success bold">Documents mis à disposition sur la prestation</p>
          </div>
        </div>
        <div dougsStepSubtitle>
          <div class="documents-available__docs">
            <div
              class="documents-available__docs__slot"
              *ngFor="
                let slotDocument of slotDocuments
                  | filterSlotDocuments: makeDocumentsAvailableModalService.selectedAttachmentIds$();
                trackBy: 'type' | trackBy
              "
            >
              <p class="tiny color-gray" *ngIf="slotDocument.label">{{ slotDocument.label }}</p>
              <dougs-file-pill
                *ngFor="let attachment of slotDocument.attachments; trackBy: 'id' | trackBy"
                [canDelete]="false"
                [attachment]="attachment"
              />
            </div>
          </div>
        </div>
      </dougs-step>
    </dougs-stepper>
  </div>
</div>
