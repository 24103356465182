import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import {
  CustomerSurveyAppointment,
  CustomerSurveyAppointmentAnswer,
  CustomerSurveyAppointmentStatusEnum,
  CustomerSurveyAppointmentStep,
  CustomerSurveyAppointmentStepTypeEnum,
} from '@dougs/surveys/dto';
import { CustomerSurveyAppointmentStateService } from '@dougs/surveys/shared';
import { Task } from '@dougs/task/dto';

@Injectable()
export class SurveyAppointmentModalComponentService {
  survey: CustomerSurveyAppointment | null = null;
  currentStep: CustomerSurveyAppointmentStep | null = null;
  shouldShowFooter = false;
  isLastStep = false;
  formGroup: FormGroup = new FormGroup({});

  private currentStepIndex = -1;
  private readonly answer: CustomerSurveyAppointmentAnswer = {
    completionStatus: CustomerSurveyAppointmentStatusEnum.dismissed,
  };

  constructor(
    @Inject(MODAL_DATA)
    public task: Task,
    private readonly modalRef: ModalRef,
    private readonly customerSurveyAppointmentStateService: CustomerSurveyAppointmentStateService,
  ) {
    this.setSurvey();
    this.updateCurrentStep();
  }

  saveAndNextStep(): void {
    if (this.currentStep?.fieldName) {
      this.saveSurveyField(this.currentStep.fieldName, this.formGroup.get(this.currentStep.fieldName)?.value);
    }
    this.updateCurrentStep();
  }

  async quitSurvey(): Promise<void> {
    let status: CustomerSurveyAppointmentStatusEnum = this.answer.completionStatus;
    if (Object.keys(this.answer).length > 1) {
      status = CustomerSurveyAppointmentStatusEnum.completed;
    }
    this.saveSurveyField('completionStatus', status);
    await this.customerSurveyAppointmentStateService.answerSurvey(this.task.id, this.answer);

    this.closeModal();
  }

  private saveSurveyField<T extends keyof CustomerSurveyAppointmentAnswer>(
    fieldName: T,
    value: CustomerSurveyAppointmentAnswer[T],
  ): void {
    this.answer[fieldName] = value;
  }

  private setSurvey(): void {
    const { survey } = this.task.metadata;
    if (!survey) {
      return;
    }

    this.survey = survey;
  }

  private updateCurrentStep(): void {
    if (!this.survey) {
      return;
    }

    const nextStepIndex: number = this.currentStepIndex + 1;
    if (nextStepIndex === this.survey.steps.length) {
      return;
    }

    this.currentStepIndex = nextStepIndex;
    this.currentStep = this.survey.steps[this.currentStepIndex];

    if (this.currentStepIndex === this.survey.steps.length - 1) {
      this.setLastStep();
    }

    this.currentStepChanged();
  }

  private setLastStep(): void {
    this.isLastStep = true;
  }

  private currentStepChanged(): void {
    this.updateShouldShowFooter();
    if (this.currentStep?.fieldName) {
      this.formGroup.addControl(this.currentStep.fieldName, new FormControl(''));
    }
  }

  private updateShouldShowFooter(): void {
    this.shouldShowFooter = this.isLastStep || this.currentStep?.type !== CustomerSurveyAppointmentStepTypeEnum.score;
  }

  private closeModal(): void {
    this.modalRef.close();
  }
}
