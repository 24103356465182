<div dougsModalTitle>
  <h6>Création d'une procédure Yousign</h6>
  <i class="fal fa-times" dougsModalClose></i>
</div>
<form [formGroup]="yousignTaskModalService.formPartners" *ngIf="yousignTaskModalService.partners$ | async">
  <div dougsModalContent="large" class="yousign-container">
    <div>
      <h5>Signataires</h5>
      <p class="mb-16">Sélectionner un ou plusieurs signataires</p>
      <dougs-checkbox
        [disabled]="yousignTaskModalService.formPartners.disabled"
        [labelAfter]="true"
        label="Tout sélectionner"
        (ngModelChange)="yousignTaskModalService.updateSelected($event)"
        [ngModelOptions]="{ standalone: true }"
        [ngModel]="false"
      />
      <div class="flex mt-8" formArrayName="partners">
        <ng-container
          *ngFor="
            let partnerGroup of yousignTaskModalService.formPartners.controls.partners.controls;
            trackBy: trackByPartnerFormGroup
          "
          [formGroup]="partnerGroup"
        >
          <dougs-partner-card-selection
            [partner]="partnerGroup.controls.partner.value"
            [errors]="partnerGroup.controls.errors.value"
            formControlName="selected"
          />
        </ng-container>
      </div>
    </div>
    <div>
      <h5>Documents</h5>
      <p class="mb-16">Sélectionner un ou plusieurs documents</p>
      <div class="pb-8 pl-8">
        <dougs-checkbox
          [labelAfter]="true"
          label="Tout sélectionner"
          (ngModelChange)="yousignTaskModalService.updateFile($event, data.task)"
          [ngModelOptions]="{ standalone: true }"
          [ngModel]="false"
        />
      </div>
      <dougs-file-card-selection formControlName="filesIds" [taskTemplate]="data.taskTemplate" [task]="data.task" />
    </div>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    <dougs-button
      type="submit"
      [disabled]="yousignTaskModalService.formPartners.invalid || yousignTaskModalService.formPartners.disabled"
      (click)="yousignTaskModalService.onSubmit(data.task, modalRef)"
    >
      Créer la procédure
    </dougs-button>
  </div>
</form>
