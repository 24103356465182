export enum CustomerSurveyAppointmentStatusEnum {
  completed = 'completed',
  dismissed = 'dismissed',
  timeExceeded = 'timeExceeeded',
}

export interface CustomerSurveyAppointmentAnswer {
  score?: number;
  comment?: string;
  completionStatus: CustomerSurveyAppointmentStatusEnum;
}

export enum CustomerSurveyAppointmentStepTypeEnum {
  input = 'input',
  textarea = 'textarea',
  score = 'score',
}

export interface CustomerSurveyAppointmentStep {
  message: string;
  type: CustomerSurveyAppointmentStepTypeEnum | null;
  fieldName?: keyof CustomerSurveyAppointmentAnswer;
  label?: string;
}

export interface CustomerSurveyAppointment {
  collaborator: { firstName?: string; avatarUrl: string };
  steps: CustomerSurveyAppointmentStep[];
  completionStatus?: CustomerSurveyAppointmentStatusEnum;
}
