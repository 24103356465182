<dougs-input-datepicker
  *ngIf="variable.value === 'workAccident'"
  label="Date de l'accident"
  [name]="'workAccidentDate' + uuid"
  [required]="true"
  [disabled]="!isEditable"
  [(ngModel)]="variableData.workAccidentDate"
/>
<div
  class="two-columns"
  [ngClass]="{
    'two-columns-with-separator':
      variable.value === 'paidVacation' ||
      variable.value === 'leaveWithoutPay' ||
      variable.value === 'unjustifiedAbsence',
  }"
>
  <div>
    <dougs-input-datepicker
      label="Du (inclus)"
      [name]="'absenceStartDate' + uuid"
      [required]="true"
      [(ngModel)]="variableData.absenceStartDate"
      (ngModelChange)="computeMinDate()"
      [disabled]="!isEditable"
      [options]="{ minDate: absenceStartDateMinDate, maxDate: absenceStartDateMaxDate }"
    />
    <dougs-radio-group
      *ngIf="
        variable.value === 'paidVacation' ||
        variable.value === 'leaveWithoutPay' ||
        variable.value === 'unjustifiedAbsence'
      "
      [(ngModel)]="variableData.absenceStartDuration"
      [name]="'absenceStartDuration' + uuid"
      [required]="true"
      [disabled]="!isEditable"
      #absenceStartDuration="ngModel"
    >
      <dougs-radio value="day">Journée</dougs-radio>
      <dougs-radio value="AM">Matin</dougs-radio>
      <dougs-radio value="PM">Après-midi</dougs-radio>
      <p *ngIf="absenceStartDuration.invalid" class="color-error tiny">Ce champ est obligatoire</p>
    </dougs-radio-group>
  </div>
  <dougs-divider
    [vertical]="true"
    *ngIf="
      variable.value === 'paidVacation' ||
      variable.value === 'leaveWithoutPay' ||
      variable.value === 'unjustifiedAbsence'
    "
  />
  <div>
    <dougs-input-datepicker
      label="Au (inclus)"
      [name]="'absenceEndDate' + uuid"
      [required]="true"
      [disabled]="!isEditable"
      [options]="{ minDate: absenceEndDateMinDate }"
      [(ngModel)]="variableData.absenceEndDate"
      (ngModelChange)="computeMinDate()"
    />
    <dougs-radio-group
      *ngIf="shouldShowAbsenceEndDuration"
      [(ngModel)]="variableData.absenceEndDuration"
      [name]="'absenceEndDuration' + uuid"
      [required]="true"
      [disabled]="!isEditable"
      #absenceEndDuration="ngModel"
    >
      <dougs-radio value="day">Journée</dougs-radio>
      <dougs-radio value="AM">Matin</dougs-radio>
      <p *ngIf="absenceEndDuration.invalid" class="color-error tiny">Ce champ est obligatoire</p>
    </dougs-radio-group>
  </div>
</div>
<div class="two-columns" *ngIf="variable.value === 'sickLeave' || variable.value === 'workAccident'">
  <div class="file-list">
    <dougs-file-input
      (uploadFiles)="addAttachment($event, 'workStopping')"
      *ngIf="isEditable"
      fileInputText="Arrêt de travail"
      [multiple]="false"
    />
    <dougs-file-pill
      (deleteFile)="removeAttachment($event)"
      *ngFor="let attachment of workStoppingAttachments"
      [attachment]="attachment"
      [canDelete]="isEditable"
    />
  </div>
  <div class="file-list" *ngIf="variable.value === 'workAccident'">
    <dougs-file-input
      (uploadFiles)="addAttachment($event, 'workAccidentDeclaration')"
      *ngIf="isEditable"
      fileInputText="Déclaration d'accident"
      [multiple]="false"
    />
    <dougs-file-pill
      (deleteFile)="removeAttachment($event)"
      *ngFor="let attachment of workAccidentDeclarationAttachments"
      [attachment]="attachment"
      [canDelete]="isEditable"
    />
  </div>
</div>
