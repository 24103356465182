<div dougsModalTitle>
  <div *ngIf="account" class="title">
    <h5>{{ account.name }}</h5>
    <i (click)="updateAccount(account)" class="ml-16 pointer fal fa-sync"></i>
  </div>
  <dougs-loader *ngIf="!account" />
  <i class="fal fa-times" dougsModalClose></i>
</div>
<div dougsModalContent>
  <ng-container *ngIf="account; else loader">
    <div class="empty" *ngIf="!hasValidatedBalances; else balancesTable">
      <p class="tiny">Aucun relevé</p>
    </div>
    <ng-template #balancesTable>
      <div class="table">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Solde fourni par le client</th>
              <th>
                Solde dans Dougs
                <a href="#" (click)="updateUseIncludedDate($event)">
                  date {{ useIncludedDate ? 'incluse' : 'exclue' }}
                </a>
              </th>
              <th>Écart</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let balance of validatedBalances; trackBy: trackById">
              <td>{{ balance.date | date: 'dd/MM/yyyy' }}</td>
              <td class="text-center">{{ balance.balance }}</td>
              <td class="text-center">{{ useIncludedDate ? balance.endOfDayBalance : balance.startOfDayBalance }}</td>
              <td class="text-center">
                {{
                  useIncludedDate
                    ? balanceDifferenceMapping[balance.id].included
                    : balanceDifferenceMapping[balance.id].excluded
                }}
              </td>
              <td>
                <div class="icon">
                  <div class="icon__download pointer" *ngIf="balanceAttachmentMapping[balance.id]?.length">
                    <i
                      *ngFor="let attachment of balanceAttachmentMapping[balance.id]; trackBy: trackById"
                      [dougsTooltip]="attachment.name"
                      (click)="downloadAttachment(attachment)"
                      class="fal fa-download"
                    ></i>
                  </div>
                  <div (click)="deleteBalance(balance)" class="color-error pointer">
                    <i class="fal fa-times"></i>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loader>
    <dougs-loader />
  </ng-template>
</div>
