<ng-container *ngIf="activitiesComponentService.refreshActivities$ | async" />
<ng-container *ngIf="activitiesComponentService.onlyPermanentValueChanges$ | async" />
<div [formGroup]="activitiesComponentService.formGroup" class="activity-search p-8">
  <input class="mb-8" dougsFormFieldControl formControlName="search" placeholder="Rechercher..." type="text" />
  <dougs-checkbox
    [isAdmin]="true"
    [showYesNo]="true"
    appearance="toggle"
    formControlName="onlyPermanent"
    noText="Fil d'activité"
    yesText="Dossier permanent"
  />
</div>
<dougs-loader *ngIf="activitiesComponentService.isLoading$ | async" />
<ng-container *ngIf="!(activitiesComponentService.isLoading$ | async)">
  <ng-container *ngIf="activitiesComponentService.filteredActivities$ | async as activities">
    <div class="p-8 activities scrollable">
      <div *ngFor="let activity of activities; trackBy: 'id' | trackBy; let i = index">
        <div *ngIf="activities | activitiesDivider: i" class="activities__divider mb-4">
          <div class="activities__divider__line"></div>
          <div class="activities__divider__content px-8">
            {{ activity.date | date: 'MMMM yyyy' }}
          </div>
        </div>
        <dougs-activity [activity]="activity" />
      </div>
      <p *ngIf="activities.length === 0" class="small">Aucune activité ne correspond à votre recherche.</p>
    </div>
    <textarea
      (keydown.enter)="activitiesComponentService.addComment($event)"
      *ngIf="activities.length"
      [formControl]="activitiesComponentService.commentFormControl"
      autosize
      dougsFormFieldControl
      placeholder="Commenter..."
      rows="1"
    ></textarea>
  </ng-container>
</ng-container>
