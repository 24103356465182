<div *ngFor="let value of state; trackBy: trackBySlot">
  <ng-container *ngIf="value.attachments.length > 0">
    <div class="line p-8" [ngClass]="{ disabled: isDisabled, selected: value.selected }">
      <dougs-checkbox
        class="bold color-primary-700"
        [ngModel]="value.selected"
        (ngModelChange)="toggleSelect($event, value)"
        [label]="value.slot.label"
        [labelAfter]="true"
      />
      <div class="pl-16">
        <dougs-file-pill
          *ngFor="let attachment of value.attachments; trackBy: 'id' | trackBy"
          [attachment]="attachment"
          [canDelete]="false"
          class="p-4"
        />
      </div>
    </div>
  </ng-container>
</div>
