<div dougsModalTitle>
  <h6>Tâche - {{ task?.title }}</h6>
  <i class="fal fa-times" dougsModalClose></i>
</div>
<div dougsModalContent>
  <div class="loader">
    <dougs-loader *ngIf="isLoading" appearance="spin" />
  </div>
  <dougs-task (closeModal)="closeTaskModal()" *ngIf="!isLoading && task" [task]="task" [modal]="true" />
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Fermer</dougs-button>
</div>
