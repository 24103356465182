<div class="slot-documents-selector">
  <div class="pl-8">
    <dougs-checkbox
      [ngModel]="slotDocuments | allSlotsSelected: makeDocumentsAvailableComponentService.selectedAttachmentIds$()"
      (ngModelChange)="makeDocumentsAvailableComponentService.toggleAllAttachments(slotDocuments)"
    >
      <p class="tiny color-primary-700 ml-8 bold">Tout sélectionner</p>
    </dougs-checkbox>
  </div>
  <div *ngFor="let slotDocument of slotDocuments; trackBy: 'type' | trackBy">
    <div
      *ngIf="slotDocument.label"
      class="slot-document py-4 pl-8"
      [ngClass]="{
        selected: slotDocument | isSlotSelected: makeDocumentsAvailableComponentService.selectedAttachmentIds$(),
      }"
    >
      <dougs-checkbox
        [ngModel]="slotDocument | isSlotSelected: makeDocumentsAvailableComponentService.selectedAttachmentIds$()"
        (ngModelChange)="makeDocumentsAvailableComponentService.toggleSelectSlot(slotDocument)"
      >
        <p class="tiny color-primary-700 ml-8 bold">{{ slotDocument.label }}</p>
      </dougs-checkbox>
    </div>
    <div
      *ngFor="let attachment of slotDocument.attachments; let index = index; trackBy: 'id' | trackBy"
      class="slot-document__attachment py-4"
      [ngClass]="{
        'pl-24': slotDocument.label,
        'pl-8': !slotDocument.label,
        selected: makeDocumentsAvailableComponentService.selectedAttachmentIds$() | isIncluded: attachment.id,
        'disable-bottom-border-radius':
          makeDocumentsAvailableComponentService.selectedAttachmentIds$()
          | isIncluded: slotDocument.attachments[index + 1]?.id,
        'disable-top-border-radius':
          (makeDocumentsAvailableComponentService.selectedAttachmentIds$()
            | isIncluded: slotDocument.attachments[index - 1]?.id) ||
          (slotDocument | isSlotSelected: makeDocumentsAvailableComponentService.selectedAttachmentIds$()),
      }"
    >
      <dougs-checkbox
        [ngModel]="makeDocumentsAvailableComponentService.selectedAttachmentIds$() | isIncluded: attachment.id"
        (ngModelChange)="makeDocumentsAvailableComponentService.toggleSelectAttachment(attachment.id)"
      >
        <dougs-pill type="white" class="ml-8">
          <i class="self-center fal fa-paperclip mr-8"></i> {{ attachment.name }}
        </dougs-pill>
      </dougs-checkbox>
    </div>
  </div>
</div>
