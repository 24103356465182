import { Injectable } from '@angular/core';
import { FlashMessagesService } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { NotValidatedOperationsStateService } from '@dougs/operations/shared';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerCreateExpenseReportOperationAdviseService extends TaskActionService {
  protected _isAvailable = true;

  constructor(
    private readonly taskService: TaskService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly notValidatedOperationsStateService: NotValidatedOperationsStateService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    if (task.metadata?.billingInvoiceId) {
      const operation: Operation | undefined = await this.notValidatedOperationsStateService.createFromBillingInvoice(
        task.metadata.billingInvoiceId,
        task.companyId,
        'Frais de conseil',
      );
      if (operation) {
        this.flashMessagesService.show(
          "La note de frais a été créée. Vous pouvez la retrouver dans l'onglet comptabilité",
          {
            type: 'success',
            timeout: 3000,
          },
        );
        await this.taskService.completeTask(task);
      }
    }
  }
}
