<div dougsModalTitle>
  <h6>Abandon de tâche déléguée</h6>
  <i class="fal fa-times" dougsModalClose></i>
</div>
<div dougsModalContent dougsModalContent="large">
  <dougs-panel-info type="warning">
    <i class="fal fa-triangle-exclamation color-warning mr-8"></i>
    <p class="small">
      Attention, la demande d’information va automatiquement abandonner la tâche déléguée en cours. Êtes-vous sûre de
      vouloir continuer ?
    </p>
  </dougs-panel-info>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
  <dougs-button (click)="onSubmit()">Continuer <i class="fal fa-arrow-right"></i></dougs-button>
</div>
