import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { combineLatest, concatMap, filter, map, Observable, withLatestFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { ModalService } from '@dougs/ds';
import { BillingInvoice } from '@dougs/subscription/dto';
import { BillingInvoiceStateService, SubscriptionStateService } from '@dougs/subscription/shared';
import { USER_FLAG, UserStateService } from '@dougs/user/shared';
import { PaymentDetailsModalComponent } from '../modals/payment-details-modal/payment-details-modal.component';
import { SubscriptionDetailsModalComponent } from '../modals/subscription-details-modal/subscription-details-modal.component';

@Injectable({
  providedIn: 'root',
})
export class BillingInvoiceComponentService {
  private readonly BILLING_INVOICES_LIMIT = 8;

  private readonly isRefreshing: WritableSignal<boolean> = signal(false);
  isRefreshing$: Signal<boolean> = this.isRefreshing.asReadonly();

  private readonly showMore: WritableSignal<boolean> = signal(false);
  showMore$: Signal<boolean> = this.showMore.asReadonly();

  private readonly showAllInvoices: WritableSignal<boolean> = signal(false);
  showAllInvoices$: Signal<boolean> = this.showAllInvoices.asReadonly();

  billingInvoices$: Observable<BillingInvoice[]> = this.billingInvoiceStateService.billingInvoices$.pipe(
    map((billingInvoices) =>
      billingInvoices?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime() || b.id - a.id),
    ),
  );

  shouldShowAllInvoices$: Observable<void> = this.billingInvoiceStateService.billingInvoices$.pipe(
    map((billingInvoices) => this.showMore.set(billingInvoices?.length > this.BILLING_INVOICES_LIMIT)),
  );

  slicedBillingInvoices$: Observable<BillingInvoice[]> = this.billingInvoices$.pipe(
    map((billingInvoices) => billingInvoices?.slice(0, this.BILLING_INVOICES_LIMIT)),
  );

  billingInvoicesToShow$: Observable<number> = combineLatest([
    this.billingInvoiceStateService.billingInvoices$,
    this.slicedBillingInvoices$,
  ]).pipe(map(([billingInvoices, slicedBillingInvoices]) => billingInvoices.length - slicedBillingInvoices.length));

  refreshBillingInvoices$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    concatMap((activeCompany) => this.billingInvoiceStateService.refreshBillingInvoices(activeCompany.id)),
  );

  refreshPendingBillingInvoices$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    concatMap((activeCompany) => this.billingInvoiceStateService.refreshPendingBillingInvoices(activeCompany.id)),
  );

  refreshBalance$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    withLatestFrom(this.userStateService.loggedInUser$),
    filter(([_, loggedInUser]) => loggedInUser.flags.includes(USER_FLAG.CAN_SEE_INVOICING_BALANCE)),
    concatMap(([activeCompany, _]) => this.subscriptionStateService.refreshBalance(activeCompany.id)),
  );

  refreshDoubtfulBalance$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    withLatestFrom(this.userStateService.loggedInUser$),
    filter(([_, loggedInUser]) => loggedInUser.flags.includes(USER_FLAG.CAN_SEE_INVOICING_BALANCE)),
    concatMap(([activeCompany, _]) => this.subscriptionStateService.refreshDoubtfulBalance(activeCompany.id)),
  );

  constructor(
    private readonly billingInvoiceStateService: BillingInvoiceStateService,
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly modalService: ModalService,
    private readonly subscriptionStateService: SubscriptionStateService,
  ) {}

  async refreshBillingInvoice(): Promise<void> {
    await this.billingInvoiceStateService.refreshBillingInvoices(this.companyStateService.activeCompany.id);
  }

  async chargeInvoice(billingInvoice: BillingInvoice): Promise<void> {
    await this.billingInvoiceStateService.chargeBillingInvoice(billingInvoice);
  }

  canChargeInvoice(billingInvoice: BillingInvoice): boolean {
    return !!(
      billingInvoice.number &&
      !billingInvoice.isPaid &&
      !billingInvoice.metadata.paidOnStripe &&
      this.userStateService.loggedInUser.isAccountantOrAdmin &&
      this.userStateService.loggedInUser.flags.includes('canFinalizeBillingInvoices')
    );
  }

  async refreshBillingInvoices(): Promise<void> {
    if (!this.isRefreshing()) {
      this.showAllInvoices.set(false);
      this.isRefreshing.set(true);
      await this.refreshBillingInvoice();
      this.isRefreshing.set(false);
    }
  }

  expandInvoices(): void {
    this.showMore.set(false);
    this.showAllInvoices.set(true);
  }

  openSubscriptionDetailsModal(): void {
    this.modalService.open(SubscriptionDetailsModalComponent);
  }

  openPaymentDetailsModal(billingInvoice: BillingInvoice): void {
    this.modalService.open(PaymentDetailsModalComponent, { data: billingInvoice });
  }
}
