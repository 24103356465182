<div class="control-panel" [ngClass]="{ 'is-open': openControlPanelComponentService.isControlPanelOpened$ | async }">
  <div (click)="openControlPanelComponentService.toggleOpen()" class="toggle-arrow">
    <i
      [ngClass]="{
        'fa-chevron-left': !(openControlPanelComponentService.isControlPanelOpened$ | async),
        'fa-chevron-right': (openControlPanelComponentService.isControlPanelOpened$ | async),
      }"
      class="fal"
    ></i>
  </div>
  <ul class="tabs">
    <li
      *ngIf="controlPanelComponentService.shouldShowSearchUserTab$ | async"
      (click)="controlPanelComponentService.openSearchUserModal()"
      class="py-8"
    >
      Dossiers
    </li>
    <li
      (click)="selectedTabComponentService.selectTab('tasks')"
      [ngClass]="{ active: (selectedTabComponentService.selectedTab$ | async) === 'tasks' }"
      class="py-8"
    >
      Tâches
    </li>
    <li
      (click)="selectedTabComponentService.selectTab('activities')"
      [ngClass]="{ active: (selectedTabComponentService.selectedTab$ | async) === 'activities' }"
      class="py-8"
    >
      Activités
      <dougs-badge
        class="activity-badge"
        *ngIf="activityStateService.permanentActivitiesNotificationCount$ | async as notifCount"
        type="success"
      >
        {{ notifCount }}
      </dougs-badge>
    </li>
  </ul>
  <dougs-control-panel-task *ngIf="(selectedTabComponentService.selectedTab$ | async) === 'tasks'" />
  <dougs-control-panel-activities *ngIf="(selectedTabComponentService.selectedTab$ | async) === 'activities'" />
  <dougs-control-panel-labels *ngIf="!(ownershipService.isMyCompany$ | async)" />
</div>
