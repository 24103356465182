@if (subscriptionPeriodComponentService.populateDateFormControl$ | async) {}
@if (subscriptionPeriodComponentService.dateFormControlChanges$ | async) {}
<div class="subscription-line">
  <div class="subscription-line__left">
    <p class="small color-primary-700">Date de fin de souscription</p>
    <dougs-input-datepicker
      [formControl]="subscriptionPeriodComponentService.dateFormControl"
      [noMargin]="true"
      label=""
    />
  </div>
</div>
