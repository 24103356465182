import { Injectable } from '@angular/core';
import { Attachment, ValidationResult } from '@dougs/core/files';
import { MetricsService } from '@dougs/core/metrics';
import { FileValidationService } from '@dougs/ds';
import { Task, TaskTemplate } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';

@Injectable()
export class TaskFilesComponentService {
  constructor(
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly attachmentValidationService: FileValidationService,
    private readonly metricsService: MetricsService,
  ) {}

  getAttachmentByType(task: Task, taskTemplate: TaskTemplate | null): { [key: string]: Attachment[] } {
    let attachmentByType: { [key: string]: Attachment[] } = {};
    if (task && taskTemplate) {
      attachmentByType = taskTemplate.fileAttachmentSlots.reduce(
        (acc: { [key: string]: Attachment[] }, fileAttachmentSlot) => {
          acc[fileAttachmentSlot.type] =
            task.attachments?.filter((attachment) => attachment.type === fileAttachmentSlot.type) || [];

          return acc;
        },
        {},
      );

      attachmentByType.attachment = task.attachments?.filter((attachment) => attachment.type === 'attachment') ?? [];
    }

    return attachmentByType;
  }

  async onRemoveTaskFile(task: Task, attachment: Attachment): Promise<void> {
    await this.controlPanelTasksStateService.removeTaskAttachment(task, attachment);
  }

  async onUploadFiles(
    task: Task,
    fileList: FileList,
    options?: {
      fileType?: string;
      isSubTask?: boolean;
    },
  ): Promise<void> {
    const files: File[] = 'length' in fileList ? Array.from(fileList) : [fileList];

    const validationResult: ValidationResult = await this.attachmentValidationService.validateFiles({
      files,
      companyId: task.companyId,
      target: task.target,
      model: {
        id: task.id,
        name: 'Task',
        fileType: options?.fileType,
      },
    });

    this.metricsService.pushMixpanelEvent('Document Validation', {
      Location: 'Task',
      OCR: true,
      Slot: options?.fileType,
      Total: files.length,
      Unprocessable: validationResult.stats?.unprocessable,
      'Auto-validated': validationResult.stats?.autoValidated,
      Verified: validationResult.stats?.verified,
      Rejected: validationResult.stats?.rejected,
    });

    if (validationResult.validatedFiles.length > 0) {
      await this.controlPanelTasksStateService.uploadTaskAttachmentsWithValidation(
        task,
        validationResult.validatedFiles,
        {
          fileType: options?.fileType,
        },
        options?.isSubTask ?? false,
      );
    }
  }
}
