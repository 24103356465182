<ng-container *ngIf="userStateService.loggedInUser$ | async as user">
  <tr [ngClass]="{ border: hasBorder, pointer: user?.isAccountantOrAdmin }">
    <td class="custom-padding month px-16 py-8">
      <p class="small">
        {{ billingInvoice.date | date: 'MMMM yyyy' | titlecase }}
      </p>
      <p class="color-admin tiny" (click)="$event.stopPropagation()" *ngIf="user.isAccountantOrAdmin">
        #{{ billingInvoice.number || (billingInvoice.subscriptionId ? 'upcoming' : 'draft') }}
      </p>
    </td>
    <td class="custom-padding center avatar py-8 pr-16">
      <dougs-avatar
        *ngIf="billingInvoice.creatorId"
        size="small"
        [dougsTooltip]="
          'Créée par ' +
          billingInvoice.creator?.profile.fullName +
          ' le ' +
          (billingInvoice.createdAt | date: 'dd/MM/yyyy')
        "
        [image]="
          billingInvoice.creator?.profile.avatarUrl
            ? billingInvoice.creator?.profile.avatarUrl
            : billingInvoice.creator?.profile.alternateAvatarUrl
        "
      />
    </td>
    <td class="custom-padding right py-8 pr-16">
      <p class="small">
        {{ billingInvoice.amountExcludingTaxes | currency: 'EUR' }}
      </p>
    </td>
    <td class="custom-padding right py-8 pr-16">
      <p class="small">
        {{ billingInvoice.amount | currency: 'EUR' }}
      </p>
    </td>
    <td class="custom-padding center py-8 pr-16">
      <p
        class="small"
        [ngClass]="{
          'color-success': billingInvoice.isPaid,
          'color-error': !billingInvoice.isPaid && billingInvoice.number,
        }"
      >
        {{
          billingInvoice.isPaid
            ? 'Payée ' + (billingInvoice.paidAt | date: 'dd/MM/yyyy')
            : billingInvoice.isPaidOnPartnerSide
              ? 'En attente du partenaire'
              : 'En attente'
        }}
      </p>
    </td>
    <td class="custom-padding right actions-column px-16 py-8">
      <div class="actions">
        <i
          [dougsTooltip]="'Télécharger la facture'"
          class="fal fa-file-pdf"
          (click)="$event.stopPropagation(); downloadInvoice($event)"
        ></i>
        <i
          [dougsTooltip]="billingInvoice.amount > 0 ? 'Tenter un débit' : 'Tenter un remboursement'"
          (click)="$event.stopPropagation(); billingInvoiceComponentService.chargeInvoice(billingInvoice)"
          *ngIf="canChargeInvoice && !billingInvoice.doNotCharge"
          class="ml-8 fal fa-credit-card"
        ></i>
      </div>
    </td>
  </tr>
</ng-container>
