import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ModalService, Pill } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskService } from '@dougs/task/ui';
import { FeedbackModalComponent } from '../../modals/feedback-modal/feedback-modal.component';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerTrustpilotFeedback extends TaskActionService {
  protected _isAvailable = true;
  protected _label = 'Donner mon avis';
  protected _tag = 'Feedback';
  protected _tagColor: Pill = 'primary';
  protected _tagIcon = 'fa-star';

  private readonly TRUSTPILOT_FEEDBACK_URL = 'https://fr.trustpilot.com/evaluate/dougs.fr';

  constructor(
    private readonly modalService: ModalService,
    private readonly taskService: TaskService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    const succeeded = !!(
      await lastValueFrom(
        this.modalService.open<boolean>(FeedbackModalComponent, {
          data: {
            feedbackUrl: this.TRUSTPILOT_FEEDBACK_URL,
            task: task,
          },
        }).afterClosed$,
      )
    ).data;

    if (succeeded) {
      await this.taskService.completeTask(task);
    }
  }

  async automaticallyExecute(task: Task | null): Promise<void> {
    if (!task) {
      return;
    }

    await this.execute(task);
  }
}
