import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { RoutingService, URL } from '@dougs/core/routing';
import { toPromise } from '@dougs/core/utils';
import { CockpitDomain, CockpitPage } from '@dougs/task/dto';
import { User } from '@dougs/user/dto';
import { USER_FLAG, UserStateService } from '@dougs/user/shared';
import { AccountingFirmStateService } from '@dougs/white-label/shared';

@Injectable({
  providedIn: 'root',
})
export class CanAccessCockpitGuard {
  constructor(
    private readonly userStateService: UserStateService,
    private readonly accountingFirmStateService: AccountingFirmStateService,
    private readonly router: Router,
    private readonly routingService: RoutingService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const loggedInUser: User = await toPromise(this.userStateService.loggedInUser$);

    if (
      !loggedInUser.isAccountantOrAdmin ||
      !this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId) ||
      loggedInUser.flags.includes(USER_FLAG.BLOCK_COCKPIT)
    ) {
      return this.router.createUrlTree([URL.HOME]);
    }

    while (route.firstChild) {
      route = route.firstChild;
    }

    const { page, domain }: { page: CockpitPage; domain: CockpitDomain } = route.data as {
      page: CockpitPage;
      domain: CockpitDomain;
    };

    // Si juste sur /me/cockpit et qu'il existe un page et un domain -> on redirige dessus.
    // Sinon, l'url est préemptive

    if (!page || !domain) {
      return this.routingService.createUrl([URL.COCKPIT, 'collaborator', loggedInUser.id.toString(), 'to-do'], true, {
        queryParams: route.queryParams,
      });
    }

    return true;
  }
}
