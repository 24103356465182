import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ControlFormFieldDirective, DougsDatePipe, FormFieldComponent, LabelFormFieldDirective } from '@dougs/ds';
import { BillingInvoice } from '@dougs/subscription/dto';
import { AddBillingInvoiceItemModalComponentService } from '../add-billing-invoice-item-modal.component.service';
import { InvoiceAlreadySelectedPipe } from './invoice-already-selected.pipe';
import { InvoiceHasCompliantMethodStatePipe } from './invoice-has-compliant-method-state.pipe';
import { InvoiceHasCompliantPaidStatePipe } from './invoice-has-compliant-paid-state.pipe';
import { SearchInvoiceComponentService } from './search-invoice.component.service';

@Component({
  selector: 'dougs-search-invoice',
  standalone: true,
  imports: [
    CommonModule,
    FormFieldComponent,
    ControlFormFieldDirective,
    LabelFormFieldDirective,
    ReactiveFormsModule,
    InvoiceAlreadySelectedPipe,
    InvoiceHasCompliantPaidStatePipe,
    InvoiceHasCompliantMethodStatePipe,
    DougsDatePipe,
  ],
  templateUrl: './search-invoice.component.html',
  styleUrls: ['./search-invoice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SearchInvoiceComponentService],
})
export class SearchInvoiceComponent {
  @Input() index!: number;

  constructor(
    public readonly addBillingInvoiceItemModalComponentService: AddBillingInvoiceItemModalComponentService,
    public readonly searchInvoiceComponentService: SearchInvoiceComponentService,
  ) {}

  trackById(index: number, item: BillingInvoice): number {
    return item.id;
  }
}
