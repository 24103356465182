<label dougsFormFieldLabel>Facture</label>
<div class="search-modal">
  <div class="form-field-line">
    <dougs-form-field>
      <input
        dougsFormFieldControl
        type="text"
        placeholder="Rechercher une facture..."
        [formControl]="searchInvoiceComponentService.searchInvoiceControl"
      />
      <i class="fal fa-search" dougsFormFieldSuffix></i>
    </dougs-form-field>
  </div>
  @if (searchInvoiceComponentService.searchInvoiceValueChanges$ | async; as invoices) {
    <div class="results">
      <ul class="search-results">
        @for (invoice of invoices; track invoice.id) {
          <li
            (click)="searchInvoiceComponentService.invoiceSearchResultClicked(invoice, index)"
            [ngClass]="{
              disable:
                (invoice
                  | invoiceAlreadySelected: addBillingInvoiceItemModalComponentService.formBillableItem : index) ||
                !(
                  invoice
                  | invoiceHasCompliantPaidState: addBillingInvoiceItemModalComponentService.formBillableItem
                  | async
                ) ||
                !(
                  invoice
                  | invoiceHasCompliantMethodState: addBillingInvoiceItemModalComponentService.formBillableItem
                  | async
                ) ||
                invoice.isDraft,
            }"
          >
            <div>
              <div class="choice-result">
                <p class="small">
                  {{ invoice.date | date: 'MMMM yyyy' | titlecase }}
                </p>
                <p class="small" [ngClass]="invoice.isPaid ? 'color-success' : 'color-error'">#{{ invoice.number }}</p>
                <p class="small">{{ invoice.amountExcludingTaxes }}&nbsp;€ HT</p>
                <p class="small">{{ invoice.amount }}&nbsp;€ TTC</p>
              </div>
              <div>
                @if (invoice.isDraft) {
                  <p class="tiny">Facture brouillon</p>
                }
                @if (
                  invoice | invoiceAlreadySelected: addBillingInvoiceItemModalComponentService.formBillableItem : index
                ) {
                  <p class="tiny">Facture déjà sélectionnée</p>
                }
                @if (
                  !(
                    invoice
                    | invoiceHasCompliantPaidState: addBillingInvoiceItemModalComponentService.formBillableItem
                    | async
                  )
                ) {
                  <p class="tiny">Impossible de choisir cette facture (états payé/impayé différents)</p>
                }
                @if (
                  !(
                    invoice
                    | invoiceHasCompliantMethodState: addBillingInvoiceItemModalComponentService.formBillableItem
                    | async
                  )
                ) {
                  <p class="tiny">Impossible de choisir cette facture (moyens de paiement différents)</p>
                }
              </div>
            </div>
          </li>
        }
      </ul>
    </div>
  }
</div>
