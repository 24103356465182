<div id="drawer-content" class="drawer__content p-24">
  <ng-container *ngIf="!makeDocumentsAvailableComponentService.documentsAvailable$()">
    <p class="tiny color-admin bold mb-4">Mise à disposition de documents temporaires au client</p>
    <dougs-task-header-summary [task]="makeDocumentsAvailableComponentService.data.task" />
    <h5 class="mt-24 mb-4">Veuillez choisir les documents</h5>
    <p class="small">
      Les documents sélectionnés apparaîtront dans l’onglet Prestations du client pendant la durée de la prestation. Une
      fois terminée, ils disparaîtront pour le client qui verra alors uniquement les documents finaux (slots visibles
      👁️).
    </p>
  </ng-container>
  <dougs-slot-documents-selector
    *ngIf="!makeDocumentsAvailableComponentService.documentsAvailable$()"
    class="mt-16"
    [slotDocuments]="makeDocumentsAvailableComponentService.slotDocuments$()"
  />
  <dougs-available-documents
    *ngIf="makeDocumentsAvailableComponentService.documentsAvailable$()"
    [slotDocuments]="makeDocumentsAvailableComponentService.slotDocuments$()"
    [task]="makeDocumentsAvailableComponentService.data.task"
  />
</div>
<div
  class="drawer__footer py-8 px-24"
  [ngClass]="{ 'justify-end': makeDocumentsAvailableComponentService.documentsAvailable$() }"
>
  <ng-container *ngIf="!makeDocumentsAvailableComponentService.documentsAvailable$(); else closeButton">
    <dougs-button color="secondary" dougsDrawerClose>Annuler</dougs-button>
    <dougs-button
      (click)="makeDocumentsAvailableComponentService.makeDocumentsAvailable()"
      [disabled]="makeDocumentsAvailableComponentService.emptySelectedAttachments$()"
      color="success"
      ><i
        class="fal mr-4 color-white"
        [ngClass]="{
          'fa-check': !makeDocumentsAvailableComponentService.documentsLoading$(),
          'fa-sync fa-spin': makeDocumentsAvailableComponentService.documentsLoading$(),
        }"
      ></i>
      Mettre à disposition ({{ makeDocumentsAvailableComponentService.selectedAttachmentsNbr$() }})
    </dougs-button>
  </ng-container>
  <ng-template #closeButton>
    <dougs-button color="secondary" dougsDrawerClose>Fermer</dougs-button>
  </ng-template>
</div>
