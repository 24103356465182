<dougs-loader *ngIf="labelStateService.isLoading$ | async" />
<div class="company-summary p-8" *ngIf="!(labelStateService.isLoading$ | async)">
  <ng-container
    *ngIf="{
      pills: labelStateService.labelsPills$ | async,
      intercomRating: labelStateService.intercomRating$ | async,
      postIts: labelStateService.postIts$ | async,
    } as labels$"
  >
    <div class="labels">
      <div class="labels__left">
        <a
          rel="noopener"
          *ngIf="labels$.intercomRating"
          [href]="labels$.intercomRating.link"
          target="_blank"
          dougsTooltip="Dernière note sur intercom"
        >
          <img
            *ngIf="labels$.intercomRating.rating === 1"
            src="images/emoji/pouting-face.png"
            alt="Pas content, pas content, shut..."
          />
          <img
            *ngIf="labels$.intercomRating.rating === 2"
            src="images/emoji/white-frowning-face.png"
            alt="Pas content"
          />
          <img *ngIf="labels$.intercomRating.rating === 3" src="images/emoji/neutral-face.png" alt="Sans avis" />
          <img *ngIf="labels$.intercomRating.rating === 4" src="images/emoji/grinning-face.png" alt="Pas mal!!" />
          <img
            *ngIf="labels$.intercomRating.rating === 5"
            src="images/emoji/star-struck-face.png"
            alt="Because i'm happy!"
          />
        </a>
        <i
          class="fal fa-info-circle pointer"
          dougsTooltip="Résumé de l'entreprise (ctrl + I)"
          (click)="controlPanelLabelsComponentService.openCompanySummaryModal()"
        ></i>
        <a
          *ngIf="(userStateService.loggedInUser$ | async).isAccountantOrAdmin"
          [routerLink]="[]"
          [queryParams]="{ 'user-conversation': 'opened' }"
          queryParamsHandling="merge"
        >
          <i
            class="fal fa-comments pointer"
            dougsTooltip="Résumé des conversations"
            (click)="controlPanelLabelsComponentService.trackUserSummariesModal()"
          ></i>
        </a>
      </div>
      <div class="labels__right">
        <dougs-post-its [postIts]="labels$.postIts" />
        <div *ngIf="labels$.pills?.length > 0">
          <dougs-user-label [pill]="pill" *ngFor="let pill of labels$.pills; trackBy: 'label' | trackBy" />
        </div>
        <div *ngIf="controlPanelLabelsComponentService.showDocuments$ | async" class="labels__right__documents">
          <dougs-file-pill-dropdown
            *ngIf="controlPanelLabelsComponentService.showStatusDocuments$ | async"
            [attachments]="controlPanelLabelsComponentService.statusDocuments$ | async"
            [lastAttachment]="controlPanelLabelsComponentService.laststatusDocument$ | async"
            label="Statuts"
          />
          <dougs-file-pill-dropdown
            *ngIf="controlPanelLabelsComponentService.showKbisDocuments$ | async"
            [attachments]="controlPanelLabelsComponentService.kbisDocuments$ | async"
            [lastAttachment]="controlPanelLabelsComponentService.lastKbisDocument$ | async"
            label="Kbis"
          />
          <dougs-file-pill-dropdown
            *ngIf="controlPanelLabelsComponentService.showInpiDocuments$ | async"
            [attachments]="controlPanelLabelsComponentService.inpiDocuments$ | async"
            [lastAttachment]="controlPanelLabelsComponentService.lastInpiDocument$ | async"
            label="INPI"
          />
        </div>
      </div>
    </div>
  </ng-container>
</div>
