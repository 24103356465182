<div dougsModalTitle>
  <h6>Détails de la facture</h6>
  <i class="fal fa-times" dougsModalClose></i>
</div>
<div dougsModalContent>
  <dougs-form-field *ngIf="canBlockInvoiceCharge">
    <ng-container *ngIf="doNotChargeChanges$ | async" />
    <label dougsFormFieldLabel>Interdire de débiter</label>
    <dougs-checkbox [formControl]="doNotChargeControl" appearance="toggle" />
  </dougs-form-field>

  <dougs-form-field *ngIf="canChangePaymentMethod">
    <ng-container *ngIf="paymentMethodChanges$ | async" />
    <label dougsFormFieldLabel>Moyen de paiement</label>
    <dougs-select dougsFormFieldControl [formControl]="paymentMethodControl">
      @for (option of paymentMethodOptions; track option.value) {
        <dougs-select-option [value]="option.value">{{ option.label }}</dougs-select-option>
      }
    </dougs-select>
  </dougs-form-field>

  <dougs-panel-info *ngIf="billingInvoice.metadata.paidOnStripe" [small]="true" class="mb-8">
    Cette facture est marquée comme payée sur Stripe.
  </dougs-panel-info>
  <dougs-billing-invoice
    (openTaskModal)="openTaskModalService.openTaskModal($event)"
    [billingInvoice]="billingInvoice"
    [forceLongLine]="true"
  />
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Fermer</dougs-button>
</div>
