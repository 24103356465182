<div class="form-layout mb-24">
  <div>
    <div class="pending-title mb-8">
      <h5>
        @if (pendingBillingInvoices.length > 1) {
          Vos prochaines factures
        } @else {
          Votre prochaine facture
        }
      </h5>
    </div>
    @for (pendingBillingInvoice of pendingBillingInvoices; track pendingBillingInvoice.id) {
      <p class="ml-4 small">à venir le {{ pendingBillingInvoice.issuableAt | date: 'PPP' }}</p>
      <dougs-billing-invoice
        (addService)="onAddService($event)"
        (openTaskModal)="openTaskModalService.openTaskModal($event)"
        (removeBillingInvoiceItem)="deleteService(pendingBillingInvoice, $event)"
        [billingInvoice]="pendingBillingInvoice"
        [canAddService]="true"
      />
    }
  </div>
</div>
