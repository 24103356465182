<ng-container *ngIf="subscriptionComponentService.populateDateFormControl$ | async" />
<ng-container *ngIf="userStateService.loggedInUser?.flags?.includes('canManagePeriodEndsAtSubscription')">
  <div class="form-layout mb-16">
    <ng-container *ngIf="subscriptionComponentService.dateFormControlChanges$ | async" />
    <div class="subscription-line">
      <div class="line-title">Date de fin de souscription</div>
      <dougs-input-datepicker [formControl]="subscriptionComponentService.dateFormControl" [noMargin]="true" label="" />
    </div>
  </div>
  <div class="mb-16">
    <dougs-divider />
  </div>
</ng-container>
