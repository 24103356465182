import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { ReplaceActiveCompanyIdInUrlPipe } from '@dougs/core/routing';
import {
  DatepickerComponent,
  DatepickerDirective,
  DropdownComponent,
  DropdownTriggerDirective,
  FormatDistanceToNowPipe,
  FormatDistanceToNowRoundedToDayPipe,
  TooltipDirective,
} from '@dougs/ds';
import { Task, TaskTemplate } from '@dougs/task/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TaskActionFacadeService } from '@dougs/task/task-actions';
import { CreationDateTooltipTextPipe } from '../../../../pipes/control-panel/task/creation-date-tooltip-text.pipe';
import { TargetIdentifierPipe } from '../../../../pipes/control-panel/task/target-identifier.pipe';
import { TaskMetadataComponentService } from '../../../../services/control-panel/task/task-metadata.component.service';
import { LegalCollaboratorComponentService } from '../../../../services/tasks/legal/legal-collaborator.component.service';
import { TaskSnoozeComponent } from '../../../tasks/tasks-list/task/task-snooze/task-snooze.component';
import { TaskUserDropdownComponent } from '../task-user-dropdown/task-user-dropdown.component';

@Component({
  selector: 'dougs-task-metadata',
  templateUrl: './task-metadata.component.html',
  styleUrls: ['./task-metadata.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    TooltipDirective,
    FormsModule,
    DatepickerDirective,
    NgClass,
    NgIf,
    DropdownComponent,
    TaskUserDropdownComponent,
    DatepickerComponent,
    FormatDistanceToNowPipe,
    FormatDistanceToNowRoundedToDayPipe,
    ReplaceActiveCompanyIdInUrlPipe,
    AsyncPipe,
    TargetIdentifierPipe,
    CreationDateTooltipTextPipe,
    ReactiveFormsModule,
    DropdownTriggerDirective,
    TaskSnoozeComponent,
  ],
  providers: [TaskMetadataComponentService],
})
export class TaskMetadataComponent {
  shouldShowMe = false;
  isAssigneeOpened = false;

  @Input({ transform: booleanAttribute }) set modal(value: boolean) {
    this.taskMetadataComponentService.modal = value;
  }

  constructor(
    public readonly taskActionFacadeService: TaskActionFacadeService,
    public readonly taskMetadataComponentService: TaskMetadataComponentService,
    public readonly legalCollaboratorComponentService: LegalCollaboratorComponentService,
  ) {}

  private _task!: Task;

  get task(): Task {
    return this._task;
  }

  @Input()
  set task(task: Task) {
    this._task = task;

    if (task) {
      this.taskMetadataComponentService.task = task;
      this.taskMetadataComponentService.populateStartDate(task);
    }
  }

  @Input()
  set taskTemplate(taskTemplate: TaskTemplate | null) {
    if (taskTemplate) {
      this.shouldShowMe = this.taskMetadataComponentService.shouldShowAssignMe(taskTemplate);
    }
  }
}
