<ng-container *ngIf="qualityControlComponentService.refreshModalTask$ | async" />
<ng-container *ngIf="qualityControlComponentService.refreshQualityControlRemarks$ | async" />
<ng-container *ngIf="qualityControlComponentService.resetRemarksState$ | async" />
<div dougsModalTitle>
  <h6>Contrôle qualité</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent="xlarge">
  <div *ngIf="modalTaskStateService.modalTask$ | async as task" class="quality-control__container">
    <div class="pr-16">
      <h6 class="mb-16">Tâche à contrôler</h6>
      <dougs-task [task]="task" />
    </div>
    <div class="quality-control__remarks pl-16">
      <ng-container *ngIf="!qualityControlComponentService.isLoadingRemarks$(); else loader">
        <h6 class="mb-16">Retours du contrôle qualité</h6>
        <ng-container *ngIf="(qualityControlRemarksStateService.completedRemarks$ | async)?.length">
          <div
            *ngFor="let remark of qualityControlRemarksStateService.completedRemarks$ | async; trackBy: 'id' | trackBy"
            class="quality-control-remark"
          >
            <dougs-avatar
              [dougsTooltip]="remark.completer?.fullName || ''"
              size="small"
              [image]="remark.completer?.avatarUrl"
            />
            <div>
              <p class="tiny">{{ remark.body }}</p>
              <p class="tiny color-gray-325">({{ remark.completedAt | date: 'dd/MM/yyyy HH:mm' }})</p>
            </div>
          </div>
          <dougs-divider />
        </ng-container>
        <ng-container *ngIf="(qualityControlRemarksStateService.uncompletedRemarks$ | async)?.length">
          <div
            *ngFor="
              let remark of qualityControlRemarksStateService.uncompletedRemarks$ | async;
              trackBy: 'id' | trackBy
            "
            class="quality-control-remark"
            [ngClass]="{
              'quality-control-remark--show-delete': qualityControlComponentService.showDeleteButton$() === remark.id,
            }"
            (mouseenter)="qualityControlComponentService.showDeleteButton(remark.id)"
            (mouseleave)="qualityControlComponentService.hideDeleteButton()"
          >
            <dougs-avatar
              [dougsTooltip]="remark.creator?.fullName || ''"
              size="small"
              [image]="remark.creator?.avatarUrl"
            />
            <div>
              <p class="tiny">{{ remark.body }}</p>
              <p class="tiny color-gray-325">({{ remark.createdAt | date: 'dd/MM/yyyy HH:mm' }})</p>
            </div>
            <i
              *ngIf="qualityControlComponentService.showDeleteButton$() === remark.id"
              (click)="qualityControlRemarksStateService.deleteQualityControlRemark(task.id, remark.id)"
              class="fal fa-times pointer"
            ></i>
          </div>
        </ng-container>
        <textarea
          (keydown)="qualityControlComponentService.preventLineBreak($event)"
          (keydown.enter)="qualityControlComponentService.addRemark($event)"
          autosize
          dougsFormFieldControl
          placeholder="Ajoutez une remarque..."
        ></textarea>
      </ng-container>
      <ng-template #loader>
        <dougs-loader />
      </ng-template>
    </div>
  </div>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Fermer</dougs-button>
</div>
