<div class="payslip__partner-info">
  <h5>Salarié</h5>
  <div class="payslip__partner-info__label pointer" (click)="employeeInfosComponentService.openPartnerModal()">
    <dougs-avatar [image]="employeeInfosComponentService.partnerProfile$()?.avatarUrl | defaultAvatarUrl" />
    <div class="payslip__partner-info__label__name">
      <p class="small bold color-primary-700 text-ellipsis">
        {{ employeeInfosComponentService.partnerProfile$()?.firstName }}
        {{ employeeInfosComponentService.partnerProfile$()?.lastName }}
      </p>
      <p class="tiny">
        {{ employeeInfosComponentService.partnerSocialInfos$()?.salary | currency: 'EUR' : 'symbol' : '0.0-0' }}
        ({{ employeeInfosComponentService.partnerSocialInfos$()?.isNet ? 'net' : 'brut' }})
      </p>
    </div>
  </div>
  <dougs-panel-info [small]="true">
    <ul>
      <li>{{ employeeInfosComponentService.partnerSocialInfos$()?.contract | uppercase }}</li>
      @if (employeeInfosComponentService.partnerSocialInfos$()?.contractStartDate) {
        <li>
          Le contrat a démarré le
          {{ employeeInfosComponentService.partnerSocialInfos$()?.contractStartDate | date: 'dd MMMM yyyy' }}
        </li>
      }
      @if (employeeInfosComponentService.partnerSocialInfos$()?.contractEndDate) {
        <li>
          Le contrat prendra fin le
          {{ employeeInfosComponentService.partnerSocialInfos$()?.contractEndDate | date: 'dd MMMM yyyy' }}
        </li>
      }
      <li>{{ employeeInfosComponentService.partnerSocialInfos$()?.personsInCharge }} personnes à charge</li>
      <li>
        {{
          employeeInfosComponentService.partnerSocialInfos$()?.hasCooperative
            ? 'Salarié affilié à la mutuelle'
            : 'Salarié non affilié à la mutuelle'
        }}
      </li>
    </ul>
  </dougs-panel-info>
</div>
