<div dougsModalTitle>
  <h6>Donner votre avis sur Dougs</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>

<div dougsModalContent>
  <h4 class="feedback-modal__greeting mb-16 text-center">
    Bonjour {{ feedbackModalService.greeting$ | async }}, merci de votre confiance&nbsp;!
  </h4>

  <div class="feedback-modal__content mb-24">
    <p class="feedback-modal__text italic small">
      Vous avez récemment partagé vos retours positifs suite à une enquête de satisfaction, et nous vous en remercions.
      Notre mission est réussie&nbsp;🚀
    </p>

    <p class="feedback-modal__text small">
      Votre retour d'expérience pourrait aider de nombreux entrepreneurs, racontez-leur comment vous réussissez à gérer
      votre entreprise avec Dougs&nbsp;👍
    </p>

    <div class="feedback-modal__cta-wrapper">
      <dougs-button color="success" size="large" (click)="feedbackModalService.openUrl()">Donner mon avis</dougs-button>
    </div>
  </div>

  <h6 class="feedback-modal__text text-center mb-16">Merci pour votre contribution et votre soutien&nbsp;!</h6>
</div>

<div dougsModalFooter>
  <dougs-button dougsModalClose color="secondary">Donner mon avis plus tard</dougs-button>
  <dougs-button [dougsModalClose]="true" color="primary">J'ai déjà donné mon avis</dougs-button>
</div>
