<div class="form-layout" *ngIf="companyStateService.activeCompany$ | async as activeCompany">
  <div class="trial-container p-16">
    <div class="trial-timeline mb-16">
      <div
        class="trial-timeline__inner"
        [ngStyle]="{
          width: (trialPercentage$ | async) + '%',
          'background-color': activeCompany.subscription?.trialRemainingDays > 7 ? 'var(--primary)' : 'var(--red)',
        }"
      ></div>
    </div>
    <div class="trial-title mb-8">
      {{ (userStateService.activeUser$ | async).profile?.firstName ?? '' }}, il vous reste
      {{ activeCompany.subscription?.trialRemainingDays ?? 0 }} jours sur votre période d'essai.
    </div>
    <div class="trial-content mb-16">
      Si vous aimez Dougs, vous pouvez activer votre abonnement dès maintenant et continuer à bénéficier de
      {{
        activeCompany.subscription?.freeMonthCount > 1
          ? 'vos ' + activeCompany.subscription?.freeMonthCount + ' mois'
          : 'votre mois'
      }}
      d'exercice
      {{
        (userStateService.activeUser$ | async)?.startPayingOnPartnerSide
          ? 'inclus dans votre offre ' + (userStateService.activeUser$ | async).startPayingOnPartnerSide + '.'
          : 'offert.'
      }}
    </div>
    <div>
      <dougs-button (click)="subscriptionComponentService.openPlansModal()"> Voir les tarifs → </dougs-button>
    </div>
  </div>
</div>
