<div
  dougsDragNDrop
  (fileDropped)="taskConversationComponentService.uploadFiles(task, $event, comment)"
  class="task-comment mb-8"
>
  <div class="task-comment__drag-file">Attacher un fichier</div>
  <dougs-avatar
    class="mr-4"
    [dougsTooltip]="comment.commenter?.profile?.fullName || ''"
    [size]="size"
    [image]="comment.commenter?.profile?.avatarUrl || comment.commenter?.profile?.alternateAvatarUrl"
  />
  <div class="task-comment__body">
    <p
      class="tiny"
      [ngClass]="{ 'is-deleted': comment | commentIsDeleted }"
      [innerHTML]="
        comment.body | convertMention: (userStateService.usersInfo$ | async) | targetBlankInHref | wrapUrlsInHtml
      "
    ></p>
    <p class="tiny color-gray-325">
      ({{ comment.createdAt | date: 'dd/MM/yyyy HH:mm' }}
      <ng-content />
      )
      <i class="fal fa-chevron-down ml-4" [dougsDropdownTrigger]="tagsDropdown"></i>
    </p>
    <dougs-pill [type]="tag | tagClass" *ngFor="let tag of comment.tags; trackBy: 'type' | trackBy; let i = index">
      {{ tag.label }}
      <i (click)="onRemoveTag(i)" class="fal fa-times ml-4 delete-tag"></i>
    </dougs-pill>
    <div class="file-list">
      <dougs-file-pill
        (deleteFile)="taskConversationComponentService.removeFile(task, $event)"
        *ngFor="let attachment of comment.attachments; trackBy: 'id' | trackBy"
        [attachment]="attachment"
      />
    </div>
  </div>
</div>
<dougs-dropdown #tagsDropdown [widthToRefElement]="false">
  <p class="tiny px-16 py-8 title-dropdown-tags">Choisissez un tag :</p>
  <dougs-dropdown-option
    [closeOnClick]="true"
    *ngFor="let tag of taskConversationComponentService.tags; trackBy: 'type' | trackBy"
    (click)="onAddTag(tag)"
  >
    {{ tag.label }}
  </dougs-dropdown-option>
</dougs-dropdown>
