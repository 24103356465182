<div class="payroll-variables" [ngClass]="{ 'payroll-variables__readonly': readOnly, 'py-16 px-24': !readOnly }">
  <dougs-blank-state
    *ngIf="partner.variables.length === 0"
    [autoWidth]="true"
    class="mb-16"
    illustration="loan-blank-slate"
  >
    <p class="tiny mt-16">Aucun élément variable défini pour ce mois</p>
  </dougs-blank-state>
  <ng-container *ngFor="let variable of partner.variables; let i = index">
    <ng-container [ngSwitch]="variable.value">
      <dougs-payroll-variable-card
        (delete)="onDelete(i)"
        *ngSwitchCase="'bonus'"
        [readonly]="readOnly"
        [isEditable]="!task.completedAt && !readOnly"
        [label]="variableDescriptors[variable.value]?.label"
        color="blue"
      >
        <dougs-payroll-variable-bonus [isEditable]="!task.completedAt && !readOnly" [variableData]="variable.data" />
      </dougs-payroll-variable-card>
      <dougs-payroll-variable-card
        (delete)="onDelete(i)"
        *ngSwitchCase="'transportSubscription'"
        [readonly]="readOnly"
        [isEditable]="!task.completedAt && !readOnly"
        [label]="variableDescriptors[variable.value]?.label"
        color="green"
      >
        <dougs-payroll-variable-transport-subscription
          [isEditable]="!task.completedAt && !readOnly"
          [variableData]="variable.data"
        />
      </dougs-payroll-variable-card>
      <dougs-payroll-variable-card
        (delete)="onDelete(i)"
        *ngSwitchCase="'packedLunch'"
        [readonly]="readOnly"
        [isEditable]="!task.completedAt && !readOnly"
        [label]="variableDescriptors[variable.value]?.label"
        color="green"
      >
        <dougs-payroll-variable-packed-lunch
          [isEditable]="!task.completedAt && !readOnly"
          [variableData]="variable.data"
        />
      </dougs-payroll-variable-card>
      <dougs-payroll-variable-card
        (delete)="onDelete(i)"
        *ngSwitchCase="'checkRestaurant'"
        [readonly]="readOnly"
        [isEditable]="!task.completedAt && !readOnly"
        [label]="variableDescriptors[variable.value]?.label"
        color="green"
      >
        <dougs-payroll-variable-packed-lunch
          [isEditable]="!task.completedAt && !readOnly"
          [variableData]="variable.data"
        />
      </dougs-payroll-variable-card>
      <dougs-payroll-variable-card
        (delete)="onDelete(i)"
        *ngSwitchCase="'kilometricIndemnity'"
        [readonly]="readOnly"
        [isEditable]="!task.completedAt && !readOnly"
        [label]="variableDescriptors[variable.value]?.label"
        color="green"
      >
        <dougs-payroll-variable-kilometric-indemnity
          [isEditable]="!task.completedAt && !readOnly"
          [variableData]="variable.data"
        />
      </dougs-payroll-variable-card>
      <dougs-payroll-variable-card
        (delete)="onDelete(i)"
        *ngSwitchCase="'overtime'"
        [isEditable]="!task.completedAt && !readOnly"
        [readonly]="readOnly"
        [label]="variableDescriptors[variable.value]?.label"
        color="blue"
      >
        <dougs-payroll-variable-overtime
          [isEditable]="!task.completedAt && !readOnly"
          [variableData]="variable.data"
          [weeks]="variableDescriptors[variable.value]?.data?.weeks"
          [small]="small"
        />
      </dougs-payroll-variable-card>
      <dougs-payroll-variable-card
        (delete)="onDelete(i)"
        *ngSwitchCase="'other'"
        [readonly]="readOnly"
        [isEditable]="!task.completedAt && !readOnly"
        [label]="variableDescriptors[variable.value]?.label"
      >
        <dougs-payroll-variable-other [isEditable]="!task.completedAt && !readOnly" [variableData]="variable.data" />
      </dougs-payroll-variable-card>
      <dougs-payroll-variable-card
        (delete)="onDelete(i)"
        *ngSwitchDefault
        [readonly]="readOnly"
        [isEditable]="!task.completedAt && !readOnly"
        [label]="variableDescriptors[variable.value]?.label"
        color="yellow"
      >
        <dougs-payroll-variable-absence
          [isEditable]="!task.completedAt && !readOnly"
          [month]="month"
          [partner]="partner"
          [task]="task"
          [variableData]="variable.data"
          [variable]="variable"
        />
      </dougs-payroll-variable-card>
    </ng-container>
  </ng-container>

  <dougs-button (click)="addVariable()" *ngIf="!task.completedAt && !readOnly" [round]="true" size="small">
    <i class="fal fa-plus mr-8"></i>
    Ajouter un élément
  </dougs-button>
</div>
