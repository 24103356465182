<div class="header container-fluid header--sticky">
  <div class="header--menu header__container container-fluid">
    <div class="header--menu__left">
      <div class="header__title">
        <img src="images/emoji/rocket.png" width="24" height="24" alt="Rocket" />
        <h5>Cockpit</h5>
      </div>
      <dougs-cockpit-main-menu />
    </div>
  </div>
</div>
