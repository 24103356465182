<div class="modal-container">
  <div dougsModalTitle>
    <h6>Vous avez {{ data?.unseenTasks?.length }} nouvelles tâches à effectuer</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    <div
      *ngFor="let unseenTask of data.unseenTasks; let isFirst = first; trackBy: trackById"
      [ngClass]="{ 'pt-16': !isFirst }"
      class="unseen-task pb-16"
    >
      <div class="unseen-task__badge">
        <dougs-badge class="badge" size="small" />
      </div>
      <div class="unseen-task__content">
        <p class="tiny">
          À réaliser entre le {{ unseenTask?.enabledAt | date: 'dd/MM/yyyy' }} et le
          {{ unseenTask?.dueDate | date: 'dd/MM/yyyy' }}
        </p>
        <p class="small color-primary-700">{{ unseenTask.title }}</p>
        <a
          *ngIf="taskActionFacadeService.getTaskActionAvailable(unseenTask)"
          (click)="taskActionFacadeService.executeTaskAction(unseenTask)"
          class="mt-8"
        >
          <b>{{ taskActionFacadeService.getTaskActionLabel(unseenTask) }}</b>
        </a>
      </div>
    </div>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Me le rappeler la prochaine fois</dougs-button>
    <dougs-button (click)="markAsSeen()" color="primary">
      <i class="fal fa-check mr-4"></i>
      Marquer comme vu
    </dougs-button>
  </div>
</div>
