import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ModalService } from '@dougs/ds';
import { Task } from '@dougs/task/dto';
import { ControlPanelTasksStateService } from '@dougs/task/shared';
import { SurveyAppointmentModalComponent } from '../../modals/survey-appointment-modal/survey-appointment-modal.component';
import { TaskActionService } from '../task-action.service';

@Injectable({
  providedIn: 'root',
})
export class TaskActionCustomerSurveyAppointmentService extends TaskActionService {
  protected _label = 'Donner mon avis';
  protected _isAvailable = true;

  constructor(
    private readonly controlPanelTasksStateService: ControlPanelTasksStateService,
    private readonly modalService: ModalService,
  ) {
    super();
  }

  async execute(task: Task): Promise<void> {
    if (!task) {
      return;
    }

    if (!task.metadata?.survey) {
      return;
    }

    await lastValueFrom(
      this.modalService.open(SurveyAppointmentModalComponent, {
        data: task,
        disableBackdropClose: true,
        disableCloseOnEscape: true,
      }).afterClosed$,
    );

    await this.controlPanelTasksStateService.getTask(task);
  }
}
