import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AutosizeModule } from 'ngx-autosize';
import {
  ButtonComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  PanelInfoComponent,
} from '@dougs/ds';

@Component({
  selector: 'dougs-delegated-task-abortion-warning-modal',
  templateUrl: './delegated-task-abortion-warning-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './delegated-task-abortion-warning-modal.component.scss',
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    AsyncPipe,
    AutosizeModule,
    ModalFooterDirective,
    ButtonComponent,
    PanelInfoComponent,
  ],
})
export class DelegatedTaskAbortionWarningModalComponent {
  constructor(private readonly modalRef: ModalRef) {}

  async onSubmit(): Promise<void> {
    this.modalRef.close(true);
  }
}
