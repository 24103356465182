<div class="table billing-invoice__list">
  <table class="mb-8">
    <thead>
      <tr>
        <th class="custom-padding px-16 py-8">Mois</th>
        <th class="custom-padding pr-16 py-8"></th>
        <th class="custom-padding right pr-16 py-8">Total HT</th>
        <th class="custom-padding right pr-16 py-8">Total TTC</th>
        <th class="custom-padding center pr-16 py-8">Statut</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      @for (
        billingInvoice of (billingInvoiceComponentService.showAllInvoices$()
          ? billingInvoiceComponentService.billingInvoices$
          : billingInvoiceComponentService.slicedBillingInvoices$
        ) | async;
        track billingInvoice.id
      ) {
        <dougs-billing-invoice-row
          [billingInvoice]="billingInvoice"
          [hasBorder]="!$last || billingInvoiceComponentService.showAllInvoices$()"
        />
      }
    </tbody>
  </table>
  @if (billingInvoiceComponentService.showMore$()) {
    <div class="billing-invoice__list__see-more">
      <p class="tiny pointer" (click)="billingInvoiceComponentService.expandInvoices()">
        Afficher toutes les factures ({{ billingInvoiceComponentService.billingInvoicesToShow$ | async }})
        <i class="color-primary-700 fal fa-chevron-down"></i>
      </p>
    </div>
  }
  @if ((userStateService.loggedInUser$ | async)?.isAccountantOrAdmin) {
    @if (subscriptionStateService.balance$ | async; as balance) {
      <div class="form-layout mb-8">
        <div class="px-16 balance">
          <p class="color-admin small">
            Solde client {{ balance.sum !== 0 ? (balance.sum > 0 ? 'débiteur' : 'créditeur') : '' }} (
            <span
              class="color-primary balance__details"
              (click)="billingInvoiceComponentService.openSubscriptionDetailsModal()"
            >
              détails
            </span>
            )
          </p>
          <p class="small">{{ balance.sum | currency: 'EUR' }}</p>
        </div>
      </div>
    }
    @if (subscriptionStateService.doubtfulBalance$ | async; as balance) {
      @if (balance.sum !== 0) {
        <div class="form-layout mb-8">
          <div class="px-16 balance">
            <p class="color-admin small">Solde client douteux {{ balance.sum > 0 ? 'débiteur' : 'créditeur' }}</p>
            <p class="small">{{ balance.sum | currency: 'EUR' }}</p>
          </div>
        </div>
      }
    }
  }
</div>
