<div dougsModalTitle>
  <h6>{{ task.title }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <dougs-avatar-message
    avatarUrl="https://images.prismic.io/test-dougs/059d75a6-aa18-472d-891d-20a83105a3f7_sarah-small.jpeg"
    size="medium"
    fullName="Sarah Jaouani, Responsable Comptabilité"
    class="mb-16"
  >
    <p class="small">
      Nous utilisons vos attestations fiscales Madelin pour vérifier la déductibilité fiscale de vos cotisations. Si
      vous n'avez pas encore reçu vos attestations, contactez votre caisse, elle pourra vous les transmettre rapidement
      par email. Nous pourrons commencer votre bilan dès que vous nous les aurez transmises.
    </p>
  </dougs-avatar-message>
  <dougs-loader *ngIf="isLoading" />
  <div
    [ngClass]="{ 'mb-8': !lastCertificate }"
    *ngFor="let certificate of form?.missingPartnersCertificateData; let lastCertificate = last"
  >
    <h6 class="mb-8">{{ certificate.partner.fullName }} :</h6>
    <div class="certificates p-16">
      <div
        class="certificates__content"
        [ngClass]="{ 'mb-16': !lastType }"
        *ngFor="let type of certificate.requiredCertificateTypes; let lastType = last"
      >
        <p class="small">Attestation de {{ type.label }}</p>
        <div class="certificates__content__file">
          <div class="file-list">
            <dougs-file-input (uploadFiles)="onUploadFiles(type, $event)" fileInputText="Attacher l'attestation" />

            <ng-container *ngFor="let attachmentId of type.taskFileAttachmentIds">
              <dougs-file-pill
                (deleteFile)="onDeleteFile(type, $event)"
                *ngIf="attachments[attachmentId]"
                [attachment]="attachments[attachmentId]"
              />
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Fermer</dougs-button>
  <dougs-button [disabled]="!form?.canComplete || isLoading" type="button" (click)="transmit()">
    Transmettre {{ form?.missingPartnersCertificateData?.length > 1 ? 'mes attestations' : 'mon attestation' }}
  </dougs-button>
</div>
