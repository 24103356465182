import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { format, parse } from 'date-fns';
import { concatMap, filter, map, Observable, tap } from 'rxjs';
import { MetricsService } from '@dougs/core/metrics';
import { Task, TaskTemplate } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';
import { TaskQueueService } from '../../task-queue.service';

@Injectable()
export class TaskMetadataComponentService {
  task!: Task;
  modal!: boolean;

  private readonly _assigneeLoading$: WritableSignal<boolean> = signal(false);
  readonly assigneeLoading$: Signal<boolean> = this._assigneeLoading$.asReadonly();

  private readonly _startDateLoading$: WritableSignal<boolean> = signal(false);
  readonly startDateLoading$: Signal<boolean> = this._startDateLoading$.asReadonly();

  startDateFormControl: FormControl<string> = new FormControl<string>('', { nonNullable: true });
  startDateValueChanges$: Observable<void> = this.startDateFormControl.valueChanges.pipe(
    filter(() => !!this.task),
    map((value) => format(parse(value, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')),
    tap((value) =>
      this.metricsService.pushMixpanelEvent('Task StartDate Changed', {
        Location: this.modal ? 'Task Modal' : 'barre latérale',
        'Previous Date': this.task.startDate,
        'New Date': value,
        'Change Date': new Date(),
      }),
    ),
    tap(() => this._startDateLoading$.set(true)),
    concatMap((value) =>
      this.taskQueueService.updateTask({
        ...this.task,
        startDate: value,
      }),
    ),
    tap((value) => this._startDateLoading$.set(false)),
  );

  constructor(
    private readonly userStateService: UserStateService,
    private readonly metricsService: MetricsService,
    private readonly taskQueueService: TaskQueueService,
  ) {}

  trackCompanyClick() {
    this.metricsService.pushMixpanelEvent('TaskModule Customer File Opened', {
      Location: this.modal ? 'Task Modal' : 'Control Panel Task',
    });
  }

  populateStartDate(task: Task): void {
    this.startDateFormControl.setValue(task.startDate.toString(), { emitEvent: false });
  }

  async assignTask(assigneeId: number | null): Promise<void> {
    this._assigneeLoading$.set(true);
    await this.taskQueueService.assignTask(this.task, assigneeId);
    this._assigneeLoading$.set(false);
  }

  shouldShowAssignMe(taskTemplate: TaskTemplate): boolean {
    return (
      !taskTemplate?.policy?.assignableRoles ||
      taskTemplate?.policy?.assignableRoles?.some((flag) => this.userStateService.loggedInUser.flags.includes(flag))
    );
  }
}
