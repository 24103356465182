import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MODAL_DATA } from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';

export interface FeedbackModalData {
  feedbackUrl: string;
}

@Injectable()
export class FeedbackModalComponentService {
  public feedbackUrl: string;

  constructor(
    @Inject(MODAL_DATA)
    private readonly modalData: FeedbackModalData,
    private readonly userStateService: UserStateService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.feedbackUrl = this.modalData.feedbackUrl;
  }

  public greeting$: Observable<string> = this.userStateService.activeUserFirstName$.pipe(
    map((firstName) => firstName || 'cher client'),
  );

  public openUrl(): void {
    this.document.defaultView?.open(this.feedbackUrl, '_blank');
  }
}
