<ng-container *ngIf="cockpitAccountingStatsComponentService.handleShowAccountingStatsColumns$ | async" />
<ng-container *ngIf="cockpitAccountingStatsComponentService.resetHoverOnChange$ | async" />
<div
  dougsAccountingStatsResponsive
  (accountingStatsUnderWidthEmitter)="accountingStatsResponsiveComponentService.setAccountingStatsUnderWidth($event)"
  class="accounting-stats"
>
  <div class="accounting-stats__container">
    <dougs-header [stats]="stats" />
    <ng-container *ngIf="!(cockpitStatsTeamComponentService.isLoadingTeamStats$ | async); else skeletonLoader">
      <dougs-collaborators *ngIf="stats?.collaborators?.length" [collaboratorStats]="stats.collaborators" />
      <div class="vertical-bar"></div>
      <ng-container *ngIf="stats?.collaborators?.length">
        <div
          [ngClass]="{ completed: cockpitAccountingStatsComponentService.isCompletedStats$ | async }"
          class="vertical-text vertical-text__team"
        >
          <p>{{ (cockpitAccountingStatsComponentService.isCompletedStats$ | async) ? 'COMPLÉTÉ' : 'À ASSIGNER' }}</p>
        </div>
        <div
          [ngClass]="{ completed: cockpitAccountingStatsComponentService.isCompletedStats$ | async }"
          class="vertical-text vertical-text__collaborators"
        >
          <p>{{ (cockpitAccountingStatsComponentService.isCompletedStats$ | async) ? 'COMPLÉTÉ' : 'À FAIRE' }}</p>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #skeletonLoader>
      <div class="skeleton-mono-direction skeleton-mono-direction__collaborators mt-48"></div>
      <div class="skeleton-mono-direction skeleton-mono-direction__collaborators mt-8"></div>
      <div class="skeleton-mono-direction skeleton-mono-direction__collaborators mt-8"></div>
    </ng-template>
  </div>
  <dougs-legend
    *ngIf="
      (cockpitAccountingStatsComponentService.hasCollaboratorStats$ | async) &&
      !(cockpitStatsTeamComponentService.isLoadingTeamStats$ | async)
    "
  />
</div>
