import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, UrlTree } from '@angular/router';
import { RoutingService, URL } from '@dougs/core/routing';
import { mergeObjects, toPromise } from '@dougs/core/utils';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';
import { filterQueryParams } from '../utils/filter-query-params.utils';

@Injectable({
  providedIn: 'root',
})
export class CanAccessTasksGuard {
  constructor(
    private readonly userStateService: UserStateService,
    private readonly routingService: RoutingService,
    @Inject(Window) private readonly window: Window,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const loggedInUser: User = await toPromise(this.userStateService.loggedInUser$);

    if (!loggedInUser.isAccountantOrAdmin) {
      return this.routingService.createUrl([URL.HOME]);
    }

    const tasksParams: string | null = this.window.localStorage.getItem('task-params');

    const filteredQueryParams: Params = filterQueryParams(route.queryParams);
    if (!Object.keys(filteredQueryParams).length && tasksParams) {
      const restoredParams = JSON.parse(tasksParams);
      delete restoredParams.primaryFilter;
      delete restoredParams.q;

      return this.routingService.createUrl([URL.TASKS], true, {
        queryParams: mergeObjects(route.queryParams, restoredParams),
      });
    }

    return true;
  }
}
