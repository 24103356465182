<ng-container *ngIf="cockpitActivityBarsComponentService.computeCollaboratorStat$ | async" />
<div class="activity-bars" dougsBarWidth #barWidthDirective="dougsBarWidth" [ngClass]="{ 'ml-8': hovered }">
  <div
    *ngIf="collaboratorStat.activity?.pointsAssignedThisWeek"
    [ngStyle]="{ width: cockpitActivityBarsComponentService.assignedThisWeekWidth + '%' }"
    class="assigned-this-week"
  >
    <p
      *ngIf="
        barWidthDirective.barWidth$()
          | showBarLabel
            : cockpitActivityBarsComponentService.assignedThisWeekWidth
            : collaboratorStat.activity.pointsAssignedThisWeek
      "
      class="tiny color-white mr-4"
    >
      {{ collaboratorStat.activity.pointsAssignedThisWeek }}
    </p>
  </div>
  <div
    *ngIf="collaboratorStat.activity?.pointsAssignedBeforeThisWeek"
    [ngStyle]="{ width: cockpitActivityBarsComponentService.assignedBeforeWeekWidth + '%' }"
    class="assigned-before-week"
  >
    <p
      *ngIf="
        barWidthDirective.barWidth$()
          | showBarLabel
            : cockpitActivityBarsComponentService.assignedBeforeWeekWidth
            : collaboratorStat.activity.pointsAssignedBeforeThisWeek
      "
      class="tiny color-white mr-4"
    >
      {{ collaboratorStat.activity.pointsAssignedBeforeThisWeek }}
    </p>
  </div>
  <div
    *ngIf="collaboratorStat.activity?.pointsCompletedThisWeek"
    [ngStyle]="{ width: cockpitActivityBarsComponentService.completedWidth + '%' }"
    class="assigned-completed"
  >
    <p
      *ngIf="
        barWidthDirective.barWidth$()
          | showBarLabel
            : cockpitActivityBarsComponentService.completedWidth
            : collaboratorStat.activity.pointsCompletedThisWeek
      "
      class="tiny color-primary-700 mr-4"
    >
      {{ collaboratorStat.activity.pointsCompletedThisWeek }}
    </p>
  </div>
  <div
    class="avg-productivity"
    [ngStyle]="{ width: cockpitActivityBarsComponentService.productivityWidth + '%' }"
  ></div>
  <p class="tiny ml-4">
    {{ cockpitActivityBarsComponentService.totalActivity }}
  </p>
</div>
