<div class="progress-bar" [ngStyle]="{ flex: tasks.relativeBarSize }">
  <div
    *ngIf="tasks.completedThisWeek.workload > 0"
    [ngClass]="{
      'progress-bar__item--start-end': tasks.remaining.workload <= 0 && tasks.todo.workload <= 0,
    }"
    class="progress-bar__item progress-bar__item--completed"
    [ngStyle]="{ 'flex-basis': tasks.completedThisWeek.relativeBarPartSize + '%' }"
  >
    <p *ngIf="tasks.completedThisWeek.showBarText" class="progress-bar__item__time tiny color-success">
      <b>{{ tasks.completedThisWeek.barText }}</b>
    </p>
    <div class="progress-bar__item__bar"></div>
  </div>
  <div
    *ngIf="tasks.todo.workload > 0"
    [ngClass]="{
      'progress-bar__item--end': tasks.remaining.workload <= 0 && tasks.completedThisWeek.workload > 0,
      'progress-bar__item--start': tasks.completedThisWeek.workload <= 0 && tasks.remaining.workload > 0,
      'progress-bar__item--start-end': tasks.remaining.workload <= 0 && tasks.completedThisWeek.workload <= 0,
    }"
    class="progress-bar__item progress-bar__item--todo"
    [ngStyle]="{ 'flex-basis': tasks.todo.relativeBarPartSize + '%' }"
  >
    <p *ngIf="tasks.todo.showBarText" class="progress-bar__item__time tiny color-purple">
      <b>{{ tasks.todo.barText }}</b>
    </p>
    <div class="progress-bar__item__bar"></div>
  </div>
  <div
    *ngIf="tasks.remaining.workload > 0"
    [ngClass]="{
      'progress-bar__item--start-end': tasks.completedThisWeek.workload <= 0 && tasks.todo.workload <= 0,
    }"
    class="progress-bar__item progress-bar__item--remaining"
    [ngStyle]="{ 'flex-basis': tasks.remaining.relativeBarPartSize + '%' }"
  >
    <p *ngIf="tasks.remaining.showBarText" class="progress-bar__item__time tiny color-error">
      <b>{{ tasks.remaining.barText }}</b>
    </p>
    <div class="progress-bar__item__bar"></div>
  </div>
</div>
