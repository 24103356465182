<ng-container *ngIf="subscriptionComponentService.refreshSignedTerms$ | async" />
<ng-container *ngIf="subscriptionStateService.signedTerms$ | async as signedTerms">
  <ng-container *ngIf="signedTerms.length">
    <div class="form-layout mb-16">
      <div class="subscription-line">
        <div class="line-title">Lettres de mission</div>
        <div class="line-content signed-terms" (click)="openedSignedTermsList = !openedSignedTermsList">
          <span *ngIf="!openedSignedTermsList">Tout afficher ({{ signedTerms.length }})</span>
          <span *ngIf="openedSignedTermsList">Fermer</span>
          <i
            class="fal ml-8"
            [ngClass]="{ 'fa-chevron-down': !openedSignedTermsList, 'fa-chevron-up': openedSignedTermsList }"
          ></i>
        </div>
      </div>
      <ng-container *ngIf="companyStateService.activeCompany$ | async as activeCompany">
        <div
          class="form-layout__no-grow self-end button-container"
          *ngIf="
            activeCompany.subscription?.activeTerms && (userStateService.loggedInUser$ | async).isAccountantOrAdmin
          "
        >
          <dougs-button
            color="admin"
            size="small"
            [fullWidth]="true"
            (click)="subscriptionComponentService.regenerateTermOfUse(activeCompany)"
          >
            <i class="fal fa-redo mr-8"></i>
            Regénérer
          </dougs-button>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="openedSignedTermsList && signedTerms">
      <div class="form-layout">
        <div>
          <dougs-divider />
          <div class="signed-terms-item" *ngFor="let signedTerm of signedTerms; trackBy: 'id' | trackBy">
            <div
              class="signed-terms-content px-8 py-8"
              (click)="subscriptionComponentService.downloadSignedTerm(signedTerm)"
            >
              <div>
                <p class="tiny color-primary-700">
                  Lettre de mission {{ signedTerm.file.metadata?.terms?.acceptedAt | date: 'yyyy' }}
                </p>
                <p class="tiny color-gray-350" *ngIf="userStateService.loggedInUser?.isAccountantOrAdmin">
                  Lettre de mission de type "{{ signedTerm.file.metadata.terms.id }}", version
                  {{ signedTerm.file.metadata.terms.version }}, signée le
                  {{ signedTerm.file.metadata?.terms?.acceptedAt | date: 'dd/MM/yyyy' }}.
                </p>
              </div>
              <div>
                <i class="fal fa-chevron-right color-primary"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
