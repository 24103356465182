<ng-container *ngIf="taskTemplate">
  <div
    class="task-card-footer__files p-8"
    [class.customer-visible]="fileAttachmentSlot.isCustomerVisible"
    *ngFor="let fileAttachmentSlot of taskTemplate.fileAttachmentSlots; trackBy: 'label' | trackBy"
    dougsDragNDrop
    (fileDropped)="taskFilesComponentService.onUploadFiles(task, $event, { fileType: fileAttachmentSlot.type })"
  >
    <div class="file-list">
      <dougs-file-input
        [fileInputText]="fileAttachmentSlot.label"
        (uploadFiles)="taskFilesComponentService.onUploadFiles(task, $event, { fileType: fileAttachmentSlot.type })"
      />
      <dougs-file-pill
        (deleteFile)="taskFilesComponentService.onRemoveTaskFile(task, $event)"
        *ngFor="let attachment of attachmentByType[fileAttachmentSlot.type]; trackBy: 'id' | trackBy"
        [attachment]="attachment"
      />
    </div>
    <i
      *ngIf="fileAttachmentSlot.isCustomerVisible"
      dougsTooltip="Les documents de ce slot seront visibles pour le client à la fin de sa prestation."
      dougsTooltipPosition="left"
      class="customer-visible-icon fal fa-eye color-primary-700"
    ></i>
  </div>
  <div
    class="task-card-footer__files p-8"
    dougsDragNDrop
    (fileDropped)="taskFilesComponentService.onUploadFiles(task, $event)"
  >
    <div class="file-list">
      <dougs-file-input
        [fileInputText]="taskTemplate?.fileAttachmentSlots.length > 0 ? 'Autres' : 'Attacher'"
        (uploadFiles)="taskFilesComponentService.onUploadFiles(task, $event)"
      />
      <dougs-file-pill
        (deleteFile)="taskFilesComponentService.onRemoveTaskFile(task, $event)"
        *ngFor="let attachment of attachmentByType['attachment']; trackBy: 'id' | trackBy"
        [attachment]="attachment"
      />
    </div>
  </div>
</ng-container>
