import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { ButtonComponent } from '@dougs/ds';
import { ProductsService } from '@dougs/revenue/products/ui';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';
import { SocialSubscriptionComponentService } from '../../../../services/social-subscription.component.service';

@Component({
  selector: 'dougs-social-plan',
  templateUrl: './social-plan.component.html',
  styleUrl: '../subscription.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [SocialSubscriptionComponentService],
  imports: [ButtonComponent, AsyncPipe],
})
export class SocialPlanComponent {
  public canUserSuspendSocialSubscription$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map((user: User) => this.socialSubscriptionComponentService.canUserSuspendSocialSubscription(user)),
  );

  constructor(
    public readonly companyStateService: CompanyStateService,
    protected readonly socialSubscriptionComponentService: SocialSubscriptionComponentService,
    private readonly userStateService: UserStateService,
    public readonly productsService: ProductsService,
  ) {}
}
