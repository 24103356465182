import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ButtonComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalService,
  ModalTitleDirective,
} from '@dougs/ds';
import { FeedbackModalComponentService } from '../../services/modals/feedback-modal.component.service';

@Component({
  selector: 'dougs-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    ButtonComponent,
    ModalFooterDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalTitleDirective,
  ],
  providers: [FeedbackModalComponentService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackModalComponent {
  constructor(
    public readonly modalService: ModalService,
    public readonly feedbackModalService: FeedbackModalComponentService,
  ) {}
}
