import { Survey } from '../dto';

const getDefaults = (): Survey => ({
  companyId: 1,
  id: 1,
  attachments: [],
  form: {
    key: '',
  },
});

export const getSurveyMock = (p?: Partial<Survey>): Survey => ({
  ...getDefaults(),
  ...p,
});
