import { CurrencyPipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { lastValueFrom, map, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { ConfirmationModalComponent, MODAL_DATA, ModalRef, ModalService } from '@dougs/ds';
import { ServiceTemplate } from '@dougs/revenue/services/dto';
import { CatalogServicesStateService } from '@dougs/services/shared';
import { BillingInvoice, BillingInvoiceItem } from '@dougs/subscription/dto';
import { BillingInvoiceStateService } from '@dougs/subscription/shared';
import { BillableItemAmountPipe } from './billable-item-amount.pipe';

@Injectable()
export class AddBillingInvoiceItemModalComponentService {
  formGroup: FormGroup = new FormGroup({
    billableItems: new FormArray([]),
  });
  billableItems: BillingInvoiceItem[] = [];

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      services?: ServiceTemplate[];
      billableServiceIds?: string[];
      addBillingInvoiceItem?: boolean;
    },
    private readonly modalService: ModalService,
    private readonly billingInvoiceStateService: BillingInvoiceStateService,
    private readonly currencyPipe: CurrencyPipe,
    private readonly catalogServicesStateService: CatalogServicesStateService,
    private readonly billableItemAmountPipe: BillableItemAmountPipe,
    private readonly companyStateService: CompanyStateService,
    private readonly modalRef: ModalRef,
  ) {}

  get formBillableItem(): FormArray {
    return this.formGroup.get('billableItems') as FormArray;
  }

  get addBillingInvoiceItem(): boolean {
    return !!this.data?.addBillingInvoiceItem;
  }

  get services(): ServiceTemplate[] {
    return this.data.services || [];
  }

  get billableServiceIds(): string[] {
    return this.data.billableServiceIds || [];
  }

  async openServicesModal(): Promise<void> {
    const { ServicesModalComponent } = await import('../services-modal/services-modal.component');
    const servicesAdded = (
      await lastValueFrom(
        this.modalService.open<ServiceTemplate[]>(ServicesModalComponent, {
          data: {
            isSelectable: true,
            isInUserMenu: false,
          },
        }).afterClosed$,
      )
    ).data;

    if (servicesAdded?.length) {
      const billableItemsAdded: BillingInvoiceItem[] = this.addServices(servicesAdded);
      this.billableItems = [...this.billableItems, ...billableItemsAdded];
      this.buildForm(billableItemsAdded);
    }
  }

  removeBillableItem(index: number): void {
    this.billableItems = this.billableItems.filter((_, i) => i !== index);
    this.formBillableItem.removeAt(index, { emitEvent: false });
  }

  addServices(services: ServiceTemplate[]): BillingInvoiceItem[] {
    return services.map((service) => ({
      billableServiceId: service.id,
      billableService: service,
      description: service.description,
      note: '',
      quantity: service.quantity || 1,
      amount: service.amount,
      options:
        service.options?.map((option) => ({
          billableServiceId: option.id,
          billableService: option,
          description: option.description,
          quantity: option.quantity || 0,
          amount: option.amount,
          metadata: option.metadata,
        })) || [],
      config: (service.config && service.config.defaults) || {},
    }));
  }

  buildForm(billableItems: BillingInvoiceItem[]): void {
    billableItems.forEach((billingInvoiceItem) => {
      const formGroup = new FormGroup({
        quantity: new FormControl(billingInvoiceItem.quantity),
        amount: new FormControl(billingInvoiceItem.amount),
        note: new FormControl(billingInvoiceItem.note),
        options: new FormArray([]),
        metadata: new FormGroup({
          creditedBillingInvoiceNumber: new FormControl(billingInvoiceItem.metadata?.creditedBillingInvoiceNumber, {
            asyncValidators: (control) => {
              if (!billingInvoiceItem.billableService?.metadata.isInvoiceCredit) {
                return of(null);
              }
              return this.billingInvoiceStateService.billingInvoices$.pipe(
                take(1),
                map((billingInvoices) => {
                  const found = billingInvoices.find((invoice) => invoice.number === control.value);
                  return found
                    ? null
                    : {
                        unknownInvoiceNumberError: `Ce numéro de facture n'est pas dans la liste des factures.`,
                      };
                }),
              );
            },
          }),
        }),
      });

      if (billingInvoiceItem.options?.length) {
        billingInvoiceItem.options.forEach((option) => {
          (formGroup.get('options') as FormArray).push(
            new FormGroup({
              quantity: new FormControl(option.quantity),
              amount: new FormControl(option.amount),
            }),
            { emitEvent: false },
          );
        });
      }

      this.formBillableItem.push(formGroup);
    });
  }

  async onSubmit(): Promise<void> {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.valid) {
      const totalAmount = this.formGroup.value.billableItems.reduce(
        (acc: number, cv: BillingInvoiceItem) => acc + this.billableItemAmountPipe.transform(cv),
        0,
      );

      const confirm =
        this.addBillingInvoiceItem ||
        (
          await lastValueFrom(
            this.modalService.open<boolean>(ConfirmationModalComponent, {
              data: {
                title: 'Confirmation',
                body: `Êtes-vous sûr de vouloir créer cette ${
                  totalAmount >= 0 ? 'facture' : "facture d'avoir"
                } de ${this.currencyPipe.transform(totalAmount, 'EUR')} HT ? Elle ne pourra être modifiée.`,
                yesText: 'Oui, créer cette facture !',
                noText: 'Non, je veux tout vérifier une dernière fois...',
                secondaryColor: true,
              },
            }).afterClosed$,
          )
        ).data;

      if (confirm) {
        this.modalRef.close(_.merge([], this.billableItems, this.formGroup.value.billableItems));
      }
    }
  }

  async populateFormGroup(): Promise<void> {
    if (this.services.length) {
      this.billableItems = this.addServices(this.services);
    } else if (this.billableServiceIds.length) {
      const services: { [key: string]: ServiceTemplate[] } | null =
        await this.catalogServicesStateService.getAdminServicesCatalog(this.companyStateService.activeCompany.id);
      if (services) {
        this.billableItems = this.addServices(
          Object.values(services)
            .reduce((acc, cv) => acc.concat(cv), [])
            .filter(
              (service) =>
                this.billableServiceIds.includes(service.id) || this.billableServiceIds.includes(service.code),
            ),
        );
      }
    }

    this.buildForm(this.billableItems);
  }

  invoiceSearchResultClicked(searchResult: BillingInvoice, index: number): void {
    this.formBillableItem
      .at(index)
      .get('metadata')
      ?.get('creditedBillingInvoiceNumber')
      ?.setValue(searchResult.number, { emitEvent: false });
    this.formBillableItem.at(index).get('amount')?.setValue(-searchResult.amountExcludingTaxes, { emitEvent: false });
  }
}
