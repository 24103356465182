<!-- Tasks -->
<ng-container *ngIf="cockpitTasksComponentService.getterParametersChanged$ | async" />
<ng-container *ngIf="cockpitTasksComponentService.resetTasks$ | async" />

<!-- Task selection -->
<ng-container *ngIf="cockpitTasksSelectionComponentService.resetSelection$ | async" />
<ng-container *ngIf="cockpitTasksSelectionComponentService.computeSelectAllAfterTaskUpdate$ | async" />

<!-- Partitions -->
<ng-container *ngIf="cockpitTasksPartitionComponentService.initializeDefaultPartition$ | async" />

<!-- Filters -->
<ng-container *ngIf="cockpitFilterComponentService.initializeFormGroupFilter$ | async" />

<!-- Categories (left menu) -->
<ng-container *ngIf="cockpitCategoriesComponentService.refreshCategories$ | async" />
<ng-container *ngIf="cockpitCategoriesComponentService.resetSelectedCategoryAndCode$ | async" />

<!-- Metrics -->
<ng-container *ngIf="cockpitMetricsComponentService.pushMixpanelAfterBulkAssign$ | async" />
<ng-container *ngIf="cockpitMetricsComponentService.pushMixpanelAfterFilterUpdate$ | async" />
<ng-container *ngIf="cockpitMetricsComponentService.pushMixpanelAfterPageChange$ | async" />
<ng-container *ngIf="cockpitMetricsComponentService.pushMixpanelAfterStartDateUpdate$ | async" />
<ng-container *ngIf="cockpitMetricsComponentService.pushMixpanelAfterWeekChange$ | async" />

<!-- Launches everything 🚀 -->
<ng-container *ngIf="cockpitComponentService.inferContextThenStartCockpit$ | async" />

<!-- Save current url -->
<ng-container *ngIf="cockpitComponentService.storeCurrentUrlOnNavigation$ | async" />

<dougs-cockpit-header-menu />

<div class="header container-fluid">
  <dougs-cockpit-header />
</div>
<div class="body container-fluid body__cockpit">
  <router-outlet #outlet="outlet" />
</div>
