<div
  dougsModalContent
  *ngIf="isLoading || !bankReconciliationForm.controls.length"
  class="modal-content-loader-container"
>
  <dougs-loader />
</div>

<form
  *ngIf="!isLoading && bankReconciliationForm.controls.length"
  [formGroup]="bankReconciliationForm"
  (ngSubmit)="checkAccountBalances()"
>
  <div dougsModalContent>
    <h5 class="mb-16">Munissez-vous de votre dernier relevé pour la banque suivante&nbsp;:</h5>

    <div class="account-container py-8 pr-16 mb-32" [ngClass]="'m-' + selectedAccount.color">
      <div class="account-title account-detail-border pl-8">
        <h6 class="account-title__bank-name">
          {{ selectedAccount.bankName }} ·
          <span class="account-name color-gray">{{ selectedAccount.accountName }}</span>
        </h6>
      </div>
    </div>

    <div
      class="form-container"
      *ngFor="let balance of bankReconciliationForm.controls; let index = index; let last = last; trackBy: trackByIndex"
      [formGroupName]="index"
      [ngClass]="{ 'mb-32': !last }"
    >
      <input type="hidden" formControlName="balanceId" />

      <dougs-bank-statement-card
        [validated]="selectedAccountBalances[index]?.validated"
        [title]="getCurrentCardTitle(bankReconciliationForm, index)"
      >
        <dougs-input-datepicker
          [noMargin]="true"
          formControlName="date"
          label="Date du solde"
          [options]="{ maxDate: getBalanceMaxDate(selectedAccountBalances[index]) }"
        />

        <dougs-form-field [noMargin]="true" [autoWidth]="true">
          <label dougsFormFieldLabel for="balance">Solde mensuel</label>
          <input
            id="balance"
            type="number"
            step="0.01"
            formControlName="balance"
            dougsFormFieldControl
            placeholder="Ex: 1 000,00 €"
          />
          <i class="fal fa-euro-sign" dougsFormFieldSuffix></i>
        </dougs-form-field>

        <dougs-panel-info
          type="warning"
          [small]="true"
          class="bank-statement-card__content__panel-info m-0"
          *ngIf="doesBalanceIsPotentiallyMissingDecimals(selectedAccountBalances[index]?.id)"
        >
          Vous n'avez pas saisi de centimes, êtes-vous certain de ne pas les avoir oubliés ?

          <div class="panel-info__button-container mt-8">
            <dougs-button
              [round]="true"
              color="primary-light"
              size="small"
              (click)="resumeCheckIfNoDecimalsWarningsLeft(selectedAccountBalances[index].id)"
            >
              J'en suis certain{{ !(companyStateService.activeCompany$ | async).owner?.profile?.isMan ? 'e' : '' }}
            </dougs-button>
            <dougs-button
              [round]="true"
              color="primary-light"
              size="small"
              (click)="removeDecimalsWarning(selectedAccountBalances[index].id)"
            >
              Oups, je les rajoute tout de suite !
            </dougs-button>
          </div>
        </dougs-panel-info>
        <dougs-panel-info
          type="error"
          class="bank-statement-card__content__panel-info"
          *ngIf="
            bankReconciliationForm.at(index).get('date')?.value &&
            !(
              bankReconciliationForm.at(index).get('balance')?.value ||
              bankReconciliationForm.at(index).get('balance')?.value === 0
            ) &&
            bankReconciliationForm.at(index).get('balance').touched &&
            bankReconciliationForm.at(index).get('date').touched
          "
        >
          Vous devez indiquer le solde du relevé bancaire.
        </dougs-panel-info>
        <dougs-panel-info
          type="error"
          class="bank-statement-card__content__panel-info"
          *ngIf="
            !bankReconciliationForm.at(index).get('date')?.value &&
            !(
              bankReconciliationForm.at(index).get('balance')?.value ||
              bankReconciliationForm.at(index).get('balance')?.value === 0
            ) &&
            bankReconciliationForm.at(index).get('balance').touched &&
            bankReconciliationForm.at(index).get('date').touched
          "
        >
          Vous devez indiquer une date ainsi que le solde du relevé bancaire correspondant.
        </dougs-panel-info>
        <dougs-panel-info
          type="error"
          class="bank-statement-card__content__panel-info"
          *ngIf="
            !bankReconciliationForm.at(index).get('date')?.value &&
            bankReconciliationForm.at(index).get('balance')?.value &&
            bankReconciliationForm.at(index).get('balance').touched &&
            bankReconciliationForm.at(index).get('date').touched
          "
        >
          Vous devez indiquer la date du solde du relevé bancaire.
        </dougs-panel-info>
      </dougs-bank-statement-card>

      <dougs-panel-info [small]="true" class="mt-8" type="warning" *ngIf="index === 0">
        Le solde indiqué sur votre relevé mensuel est différent de celui inscrit sur Dougs. Nous avons besoin de
        vérifier le solde du mois précédent. Il se peut que nous remontions jusqu'aux informations vérifiées lors de la
        création de votre compte Dougs.
      </dougs-panel-info>
    </div>
  </div>

  <div dougsModalFooter>
    <dougs-button color="secondary" (click)="previousStep()">Retour</dougs-button>

    <dougs-button
      type="submit"
      [disabled]="!!(bankReconciliationForm.invalid || potentiallyForgotDecimalsBalanceIds.length || isLoading)"
    >
      Vérifier
    </dougs-button>
  </div>
</form>
