<div class="accounting-stats-collaborators mt-32">
  <div
    class="collaborator-line header-line py-4 px-8"
    [ngClass]="{
      bilan: cockpitAccountingStatsComponentService.showClassicBilan$ | async,
    }"
  >
    <div></div>
    <p class="tiny">Total</p>
    <p class="tiny">Prio</p>
    <p class="tiny">Bilans</p>
    <div
      class="ml-8 activity-bilan-toggle"
      *ngIf="cockpitAccountingStatsComponentService.activityBilanToggleMode$ | async"
    >
      <div
        class="px-8 pb-8 activity-bilan-toggle__category pointer"
        (click)="accountingStatsResponsiveComponentService.setActivityMode()"
        [ngClass]="{ active: accountingStatsResponsiveComponentService.isActivityMode$() }"
      >
        <p class="tiny">
          <span class="color-primary-700">Activité</span>
          (en points)
        </p>
      </div>
      <div
        class="px-8 pb-8 activity-bilan-toggle__category pointer"
        (click)="accountingStatsResponsiveComponentService.setBilanMode()"
        [ngClass]="{ active: accountingStatsResponsiveComponentService.isBilanMode$() }"
      >
        <p class="tiny">
          <span class="color-primary-700">Bilans</span>
          (en nombres)
        </p>
      </div>
    </div>
    <p *ngIf="!(cockpitAccountingStatsComponentService.activityBilanToggleMode$ | async)" class="tiny ml-8">
      Activité (en points)
    </p>
    <p class="tiny" *ngIf="cockpitAccountingStatsComponentService.showClassicBilan$ | async">Bilans (en nombre)</p>
  </div>
  <dougs-collaborator-line
    *ngFor="let collaboratorStat of collaboratorStats; trackBy: 'id' | trackBy"
    [collaboratorStat]="collaboratorStat"
  />
  <div class="collaborator-vertical-bar"></div>
  <div class="collaborator-vertical-text">
    <p>SUIVI</p>
  </div>
</div>
