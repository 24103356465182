<div dougsModalTitle>
  <h6>{{ title }}</h6>
  <i *ngIf="canClose()" dougsModalClose class="fal fa-times"></i>
</div>

<div dougsModalContent>
  <dougs-avatar-message [avatarUrl]="avatarUrl" [fullName]="avatarFullName" size="medium">
    <p class="small mb-8">
      Afin de préparer au mieux votre bilan, nous avons besoin de votre appel de cotisations RSI / URSSAF.
      <a href="#" (click)="$event.preventDefault(); onClickHelp()">
        Vous trouverez un exemple de ce document en cliquant sur ce lien.
      </a>
      <br />
      Vous pouvez trouver cet appel de cotisations sur le site
      <a rel="noopener" href="https://www.urssaf.fr" target="_blank">www.urssaf.fr/</a>
      .
      <br />
      Pouvez-vous attacher votre appel ci-dessous ? Merci !
    </p>
    <p class="small mb-8">
      Si besoin, vous pouvez lire l'article d'aide que nous vous avons préparé :
      <br />
      <a
        rel="noopener"
        href="http://aide.dougs.fr/fr/articles/3195220-recuperer-l-avis-d-appel-de-cotisations-rsi-urssaf"
        target="_blank"
      >
        comment récupérer l'avis d'appel de cotisations RSI / URSSAF
      </a>
    </p>
  </dougs-avatar-message>

  <dougs-divider class="mt-32" />

  <ng-container *ngIf="taskForm">
    <ng-container *ngFor="let partner of taskForm.inlineGroup.value.partnersData; trackBy: trackByIdPartners">
      <div class="urssaf-documents-list-partners my-8">
        <div class="urssaf-partner mr-32">
          <dougs-avatar class="mr-8" [image]="partner.avatarUrl" size="small" />
          <p class="small">{{ partner.fullName }}</p>
        </div>

        <div class="urssaf-button-and-file-attached">
          <div class="file-list">
            <dougs-file-input
              [fileInputText]="'Attacher l\'appel de cotisation ' + taskForm.inlineGroup.value.year"
              [multiple]="false"
              (uploadFiles)="onUploadFiles($event, partner.id)"
            />
            <dougs-file-pill
              class="urssaf-dougs-pill"
              *ngFor="let attachment of _attachments.get(partner.id); trackBy: trackByIdAttachments"
              [attachment]="attachment"
              (deleteFile)="onRemoveAttachment($event)"
            />
          </div>
        </div>
      </div>

      <dougs-divider />
    </ng-container>

    <dougs-panel-info class="mt-32" *ngIf="taskForm.inlineGroup.value?.warningMessage" type="warning">
      {{ taskForm.inlineGroup.value.warningMessage }}
    </dougs-panel-info>
  </ng-container>
</div>

<div dougsModalFooter>
  <dougs-button *ngIf="taskForm" [disabled]="!taskForm.inlineGroup.value.canComplete" (click)="onCloseModal()">
    Terminer
  </dougs-button>
</div>
