<form
  *ngIf="addBillingInvoiceItemModalComponentService.formGroup"
  [formGroup]="addBillingInvoiceItemModalComponentService.formGroup"
  (ngSubmit)="addBillingInvoiceItemModalComponentService.onSubmit()"
>
  <div dougsModalTitle>
    <h6>
      {{
        addBillingInvoiceItemModalComponentService.addBillingInvoiceItem
          ? 'Ajouter une prestation'
          : 'Créer une facture'
      }}
    </h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent formArrayName="billableItems">
    <fieldset
      class="mb-8"
      *ngFor="
        let control of addBillingInvoiceItemModalComponentService.formBillableItem.controls;
        let i = index;
        trackBy: trackByIndex
      "
    >
      <legend class="billing-invoice-item__header">
        <span>{{ addBillingInvoiceItemModalComponentService.billableItems[i].description }}</span>
        <i class="fal fa-trash" (click)="addBillingInvoiceItemModalComponentService.removeBillableItem(i)"></i>
      </legend>

      <dougs-panel-info
        type="info"
        class="mb-16"
        *ngIf="
          addBillingInvoiceItemModalComponentService.billableItems[i].billableService.metadata?.isInvoiceCredit &&
          addBillingInvoiceItemModalComponentService.billableItems[i].billableService.remarks
        "
      >
        <p class="small">
          {{ addBillingInvoiceItemModalComponentService.billableItems[i].billableService.remarks }}
        </p>
      </dougs-panel-info>
      <div [formGroupName]="i">
        <div class="form-layout__two-columns mb-8">
          <dougs-form-field [noMargin]="true">
            <label dougsFormFieldLabel>Quantité</label>
            <input formControlName="quantity" dougsFormFieldControl type="number" [min]="0" [step]="1" />
          </dougs-form-field>
          <dougs-form-field [noMargin]="true">
            <label dougsFormFieldLabel>Prix unitaire (€ HT)</label>
            <input
              formControlName="amount"
              dougsFormFieldControl
              type="number"
              [step]="0.01"
              [min]="addBillingInvoiceItemModalComponentService.billableItems[i].billableService.config.min"
              [max]="addBillingInvoiceItemModalComponentService.billableItems[i].billableService.config.max"
            />
          </dougs-form-field>
        </div>
        <div
          [ngClass]="{
            'mb-8':
              addBillingInvoiceItemModalComponentService.billableItems[i].billableService.metadata?.isInvoiceCredit ||
              addBillingInvoiceItemModalComponentService.billableItems[i].options?.length,
          }"
        >
          <dougs-form-field [noMargin]="true">
            <label dougsFormFieldLabel>Remarques</label>
            <input
              formControlName="note"
              dougsFormFieldControl
              type="text"
              placeholder="Ex. : Social du mois de juin"
            />
          </dougs-form-field>
        </div>
        <div
          *ngIf="addBillingInvoiceItemModalComponentService.billableItems[i].billableService.metadata?.isInvoiceCredit"
        >
          <dougs-search-invoice [index]="i" />
        </div>
        <dougs-divider
          class="mb-16"
          *ngIf="addBillingInvoiceItemModalComponentService.billableItems[i].options?.length"
        />
        <div
          class="billing-invoice-item__option mb-16"
          formArrayName="options"
          *ngFor="
            let option of addBillingInvoiceItemModalComponentService.billableItems[i].options;
            trackBy: trackById;
            let j = index
          "
        >
          <h6 class="mb-8">{{ option.description }}</h6>
          <ng-container [formGroupName]="j">
            <div class="form-layout__two-columns">
              <dougs-form-field [noMargin]="true">
                <label dougsFormFieldLabel>Quantité</label>
                <input formControlName="quantity" dougsFormFieldControl type="number" [min]="0" [step]="1" />
              </dougs-form-field>
              <dougs-form-field [noMargin]="true">
                <label dougsFormFieldLabel>Prix unitaire (€ HT)</label>
                <input
                  formControlName="amount"
                  dougsFormFieldControl
                  type="number"
                  [step]="0.01"
                  [min]="option.billableService.config.min"
                  [max]="option.billableService.config.max"
                />
              </dougs-form-field>
            </div>
          </ng-container>
        </div>
        <dougs-divider class="my-8" />
        <div class="billing-invoice-item__total mt-16">
          <div class="billing-invoice-item__total__ht">
            <div class="billing-invoice-item__total__ht__title">
              <h5>Total HT</h5>
            </div>
            <div class="billing-invoice-item__total__ht__amount">
              <h5>
                {{
                  addBillingInvoiceItemModalComponentService.formBillableItem.value[i]
                    | billableItemAmount
                    | currency: 'EUR'
                }}
              </h5>
            </div>
          </div>
          <div class="billing-invoice-item__total__ttc">
            <div class="billing-invoice-item__total__ttc__title">Total TTC</div>
            <div class="billing-invoice-item__total__ttc__amount">
              {{
                addBillingInvoiceItemModalComponentService.formBillableItem.value[i]
                  | billableItemAmountIncludingTaxes
                  | currency: 'EUR'
              }}
            </div>
          </div>
        </div>
      </div>
    </fieldset>
    <dougs-button (click)="openServicesModal()" [fullWidth]="true" color="primary-light">
      <i class="fas fa-plus-circle mr-4 color-primary"></i>
      Ajouter une ligne
    </dougs-button>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    <dougs-button type="submit">
      {{
        addBillingInvoiceItemModalComponentService.addBillingInvoiceItem
          ? 'Sauvegarder les prestations'
          : 'Créer la facture'
      }}
    </dougs-button>
  </div>
</form>
