@if (collaboratorFormComponentService.refreshCatalog$ | async) {}
@if (collaboratorFormComponentService.populateFormArray$ | async) {}
@if (collaboratorFormComponentService.finalizeEmployeeForm$ | async) {}
@if (collaboratorFormComponentService.saveCollaboratorVariables$ | async) {}

<div class="collaborator-form p-8">
  <h5 class="color-admin">Variables de paie à saisir</h5>
  @if (collaboratorFormComponentService.catalogLoading$()) {
    <dougs-loader />
  } @else {
    @for (formGroup of collaboratorFormComponentService.formArray.controls; track $index) {
      <dougs-collaborator-variable-card
        [dougsDetectFormChanges]="collaboratorFormComponentService.formArrayHasBeenTouched$ | async"
        [variableFormGroup]="formGroup"
        [disabled]="collaboratorPayslipModalComponentService.selectedPayslipLineIsCompleted$ | async"
        [formGroupIndex]="$index"
      />
    }
    @if ((collaboratorPayslipModalComponentService.selectedPayslipLineIsCompleted$ | async) !== true) {
      <div class="collaborator-form__add py-8">
        <dougs-button (click)="collaboratorFormComponentService.addNewVariable()" size="small" color="primary">
          <i class="fal fa-plus color-white mr-4"></i>
          Ajouter un élément
        </dougs-button>
      </div>
    }
  }
</div>
