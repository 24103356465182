<div class="form-container" [formGroup]="collaboratorCardComponentService.cardFormGroup">
  <div class="form-layout__two-columns">
    <dougs-input-datepicker [noMargin]="true" label="Date de l'acompte" formControlName="date" />
    <dougs-form-field [noMargin]="true">
      <label dougsFormFieldLabel>Montant de l'acompte</label>
      <input dougsFormFieldControl type="number" formControlName="amount" />
      <i class="fal fa-euro-sign" dougsFormFieldSuffix></i>
      @if (formService.isControlInvalid(collaboratorCardComponentService.cardFormGroup?.controls?.amount)) {
        <span dougsFormFieldError> Ce champ est requis </span>
      }
    </dougs-form-field>
  </div>
</div>
