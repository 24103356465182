<form #payrollSurveyForm="ngForm" (ngSubmit)="onSubmit(payrollSurveyForm)">
  <div dougsModalTitle>
    <h6 class="mr-16">Variables de paie pour {{ data.metadata?.payslipDate | date: 'MMMM yyyy' }}</h6>
    <i class="fal fa-times" dougsModalClose></i>
  </div>
  <div dougsModalContent="large">
    <dougs-loader *ngIf="isLoading" />
    <dougs-avatar-message
      *ngIf="!data.completedAt && !isLoading"
      avatarUrl="https://images.prismic.io/test-dougs/e01ad0a8-9e97-4288-9fe7-50c3eeedbda2_patrick.jpeg"
      class="mb-16"
      fullName="Patrick, Expert comptable"
      size="medium"
    >
      <ng-container *ngIf="form?.inlineGroup?.value?.items?.length > 0">
        <p class="small mb-16">
          Afin de réaliser vos bulletins de paie du mois de {{ data.metadata?.payslipDate | date: 'MMMM yyyy' }}, nous
          avons besoin des éléments variables pour vous et/ou vos salariés. Vous pouvez les ajouter en cliquant sur le
          bouton “Ajouter un élément”. À défaut de réponse avant le <b>{{ data.dueDate | date: 'dd MMMM' }}</b>
          , vos bulletins de paie seront édités sans variable. En cas de retard, ils seront à reporter le mois suivant.
          Veuillez noter qu’il n’est pas possible de modifier depuis l’application Dougs les variables de paie que vous
          avez préalablement validées.
        </p>
        <ul class="pl-24">
          <li>En cas d’erreur sur un variable, vous pouvez nous contacter pour que nous réalisions la modification</li>
          <li>En cas d’oubli d’une variable, celui-ci sera à reporter sur le bulletin de salaire du mois suivant.</li>
        </ul>

        <p *ngIf="payslipMonth === 1 || payslipMonth === 7 || payslipMonth === 11" class="small mb-16">
          <i>
            Les dates de transmission des éléments variables ont changé ? En effet, pour avoir le temps de réaliser vos
            bulletins (mois plus court ou fêtes de fin d’année par exemple)et pour nous adapter à vos contraintes
            (fermeture annuelle d’été), nous pouvons les modifier.
          </i>
        </p>

        <p class="small">
          À défaut de réponse avant le
          <b>{{ data.dueDate | date: 'dd MMMM' }}</b>
          , les variables seront intégrées sur le bulletin du mois prochain.
        </p>
      </ng-container>
      <p *ngIf="form?.inlineGroup?.value?.items?.length === 0" class="small mb-16">
        Bonjour, vous n'avez pas de salarié configuré dans vos paramètres Dougs ce mois-ci.
        <br />
        Pour confirmer que c'est bien le cas, vous pouvez cliquer sur le bouton &laquo;&nbsp;
        <em>Confirmer</em>
        &nbsp;&raquo; de cette fenêtre, sinon rendez-vous dans vos paramètres pour configurer vos salariés.
        <br />
        À défaut de réponse avant le
        <b>{{ data.dueDate | date: 'dd MMMM' }}</b>
        , aucun bulletin ne sera généré pour ce mois.
      </p>
    </dougs-avatar-message>
    <div *ngFor="let partner of form?.inlineGroup?.value?.items" class="partner-payroll-variable p-16 mb-16">
      <div class="partner-payroll-variable__avatar">
        <dougs-avatar [image]="partner.avatarUrl" class="mb-16" size="large" />
        <h6 class="mb-8 partner-payroll-variable__avatar__name" (click)="openPartnerModal(partner)">
          {{ partner.fullName }}
        </h6>
        <p class="tiny">
          Salaire {{ partner.monthlyIncomeType }} {{ partner.monthlyIncome | currency: 'EUR' : 'symbol' : '0.0-0' }}
        </p>
      </div>
      <dougs-payroll-variables
        (updateVariable)="cdr.markForCheck()"
        [month]="data.metadata?.payslipDate"
        [partner]="partner"
        [task]="data"
        [variableDescriptors]="form?.inlineGroup?.value?.variableDescriptors"
      />
    </div>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Plus tard</dougs-button>
    <dougs-button [disabled]="!(data && !data.completedAt)" type="submit">Confirmer</dougs-button>
  </div>
</form>
