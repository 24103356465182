<div
  class="collaborator-line py-4 px-8 pointer"
  [ngClass]="{
    bilan: cockpitAccountingStatsComponentService.showClassicBilan$ | async,
  }"
  (mouseenter)="cockpitAccountingStatsComponentService.setHoveredLine(collaboratorStat.id)"
  (mouseleave)="cockpitAccountingStatsComponentService.setHoveredLine(null)"
>
  <div
    class="img-label"
    [ngClass]="{
      'pl-8': (cockpitAccountingStatsComponentService.hoveredCollaboratorLine$ | async) === collaboratorStat.id,
    }"
  >
    <img [src]="collaboratorStat.profile.avatarUrl | defaultAvatarUrl" />
    <p class="tiny color-primary-700 name">{{ collaboratorStat.profile.fullName | titlecase }}</p>
  </div>
  <p
    class="small color-primary-700"
    [ngClass]="{
      'pl-8': (cockpitAccountingStatsComponentService.hoveredCollaboratorLine$ | async) === collaboratorStat.id,
    }"
  >
    {{ cockpitLineComponentService.totalPoints$ | async }} pts
  </p>
  <p
    class="small color-primary-700"
    [ngClass]="{
      'pl-8': (cockpitAccountingStatsComponentService.hoveredCollaboratorLine$ | async) === collaboratorStat.id,
    }"
  >
    {{ collaboratorStat.activity.priorityTasks }}
  </p>
  <p
    class="small color-primary-700"
    [ngClass]="{
      'pl-8': (cockpitAccountingStatsComponentService.hoveredCollaboratorLine$ | async) === collaboratorStat.id,
    }"
  >
    {{ collaboratorStat.activity.balanceSheets }}
  </p>
  <div class="collaborator-activty">
    <dougs-collaborator-activity-bars
      *ngIf="cockpitAccountingStatsComponentService.showActivityBar$ | async"
      @fadeInOut
      [@.disabled]="!(cockpitAccountingStatsComponentService.activityBilanToggleMode$ | async)"
      [hovered]="(cockpitAccountingStatsComponentService.hoveredCollaboratorLine$ | async) === collaboratorStat.id"
      [collaboratorStat]="collaboratorStat"
    />
    <dougs-collaborator-bilan-bars
      *ngIf="cockpitAccountingStatsComponentService.isBilanModeToggle$ | async"
      @fadeInOut
      [hovered]="(cockpitAccountingStatsComponentService.hoveredCollaboratorLine$ | async) === collaboratorStat.id"
      [collaboratorStat]="collaboratorStat"
    />
  </div>
  <div *ngIf="cockpitAccountingStatsComponentService.showClassicBilan$ | async" class="collaborator-bilan">
    <dougs-collaborator-bilan-bars [collaboratorStat]="collaboratorStat" />
  </div>
</div>
